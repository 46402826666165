import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, asyncScheduler } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';
import { ToastService } from 'src/app/services/component/toast.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService, private router: Router, private toastService: ToastService,) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const authorized = this.authenticationService.authenticationSubject.value;
        if (!authorized) {
            this.authenticationService.clearSession();
            this.router.navigate(['']).catch((err: Error) => this.toastService.showDanger(err.message));
        }
        return authorized;
    }
}
