<div class="modal-container d-flex flex-column">
  <div class="modal-header">
    <span class="h5 fw-bold d-flex justify-content-center w-100">{{evalTitle}}</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"
      [tabIndex]="-1">
    </button>
  </div>

  <div class="modal-body px-4 pt-0">
    <form [formGroup]="form">
      <div class="row">
        <div class="d-flex justify-content-center">
          <div *ngIf="isAlreadyAnswered && (transformedMetadata | keyvalue)?.length && !editable"
            class="col-7 d-flex flex-column">
            <table class="table">
              <tbody>
                <tr *ngFor="let metadata of transformedMetadata | keyvalue">
                  <th scope="col" class="tableHeading">{{metadata.key}}</th>
                  <th scope="col" class="tableHeading d-flex justify-content-center">{{metadata.value}}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="isAlreadyAnswered && staticRefrenceChart && !editable && isEvalFluenza"
            class="col-11 d-flex flex-column">
            <div class="d-flex justify-content-center my-3 final-text">
              <span class="mx-2">{{'FinalValue' | translate}}</span>
              <p class="mx-2">{{survey.value}}</p>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="evalHeading tableHeading" *ngFor="let metadata of staticRefrenceChart"
                    [class.table-highlighted-text]="checkFinalValue === metadata.key">
                    {{metadata.key}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td *ngFor="let metadata of staticRefrenceChart"
                    [class.box-yellow]="checkFinalValue === metadata.key">
                    {{metadata.value}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ng-container *ngIf="evalType === evalTypeEnum.H_and_Y">
          <div class="form-row p-3 mb-2 col-lg-6 col-md-12 col-sm-12 col-xs-12"
            *ngFor="let question of selectionTypeAnswer; let quesIndex = index">
            <div class="d-flex justify-content-between">
              <span class="modal-title text-success h3 fw-bold">{{ (quesIndex+1) && (quesIndex +1) < 10 ? ('0' +
                  (quesIndex+1)) : ((quesIndex+1) || '' ) }}</span>
                  <span class="modal-title mt-3"></span>
            </div>
            <label [for]="question?.questionAnswerId" class="mt-3 h6 fw-bold w-100">{{question?.text || ''}}</label>
            <p class="mb-3 h5 fw-bolder mb-3 w-100">{{question?.name}}</p>
            <div class="col-1 d-flex flex-row justify-content-between">
              <input type="radio" [formControlName]="questionIdHnY" [name]="questionIdHnY"
                [id]="question?.questionAnswerId" [value]="question?.questionAnswerId">
              <label [for]="question?.questionAnswerId">{{question?.value}}</label>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="(editable || (!transformedMetadata || !hasStaticReferenceData)) && evalType !== evalTypeEnum.H_and_Y">
          <div class="form-row p-3 mb-2 col-lg-6 col-md-12 col-sm-12 col-xs-12"
            *ngFor="let question of questions; index as idx">
            <ng-container [ngTemplateOutlet]="control"
              [ngTemplateOutletContext]="{ question, index: (idx || 0) + 1 }"></ng-container>

            <ng-template #control let-question="question" let-quesIndex="index">
              <div class="d-flex justify-content-between">
                <span class="modal-title text-success h3 fw-bold">{{ quesIndex && quesIndex < 10 ? ('0' + quesIndex) :
                    (quesIndex || '' ) }}</span>
                    <span class="modal-title mt-3"></span>
              </div>
              <span class="modal-title h6 text-uppercase">{{ 'Assesment' | translate }}</span>
              <ng-container
                *ngIf="evalType===evalTitleEnum.MOCA||evalType===evalTypeEnum.MMSE || evalType===evalTypeEnum.ABC && question.minQuestionValue>=0&& question.maxQuestionValue">
                <ng-container [ngTemplateOutlet]="titleAndName" [ngTemplateOutletContext]="{ question }"></ng-container>
                <div class="row">
                  <ng-container
                    *ngFor="let i of getRadioValue(question.minQuestionValue,question.maxQuestionValue,evalType)">
                    <div class="mb-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 p-2 ">
                      <input type="radio" class="margin-right-5px" [formControlName]="question.questionId?.toString()"
                        [name]="question.questionId?.toString()" [id]="question?.questionAnswerId" [value]="i">
                      <label [for]="question?.questionAnswerId">{{i}}{{evalType===evalTypeEnum.ABC?'%':''}}</label>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <div>
                <div
                  *ngIf="(evalType!==evalTitleEnum.MOCA && evalType!==evalTypeEnum.MMSE && evalType!==evalTypeEnum.ABC) &&question?.type === questionTypeEnum.PLAIN_NUMBER_VALUE">
                  <ng-container [ngTemplateOutlet]="titleAndName"
                    [ngTemplateOutletContext]="{ question }"></ng-container>
                  <input
                    *ngIf="evalType === evalTitleEnum.FOUR_STEPS"
                    type="number" min="0" id="numericInput" step='0.01'
                    class="form-control shadow-sm p-3 border border-2"
                    [formControlName]="question.questionId?.toString()"
                    (change)="question?.title === 'TIMED UP & GO'|| question?.title === 'TUG DUAL TASK'?changeAnswerValue(question,$event):false">
                  <input
                    *ngIf="evalType !== evalTitleEnum.FOUR_STEPS && !((evalType === evalTitleEnum.MOCA) && (question.maxQuestionValue === -1)) && (evalType !== evalTypeEnum.H_and_Y && evalType !== evalTypeEnum.MINI_BEST)"
                    type="number" min="0" id="numericInput" oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    class="form-control shadow-sm p-3 border border-2"
                    [formControlName]="question.questionId?.toString()"
                    (change)="question?.title === 'TIMED UP & GO'|| question?.title === 'TUG DUAL TASK'?changeAnswerValue(question,$event):false">

                  <input
                    *ngIf="!((evalType === evalTitleEnum.MOCA) && (question.maxQuestionValue === -1)) && (evalType === evalTypeEnum.H_and_Y || evalType === evalTypeEnum.MINI_BEST)"
                    type="number" min="0" id="numericInput"
                    onkeydown="return event.keyCode !== 69  && event.keyCode !== 189"
                    class="form-control shadow-sm p-3 border border-2"
                    [formControlName]="question.questionId?.toString()"
                    (change)="question?.title === 'TIMED UP & GO'|| question?.title === 'TUG DUAL TASK'?changeAnswerValue(question,$event):false">
                </div>

                <div *ngIf="question?.type === questionTypeEnum.PLAIN_TEXT_VALUE">
                  <ng-container [ngTemplateOutlet]="titleAndName"
                    [ngTemplateOutletContext]="{ question }"></ng-container>
                  <textarea *ngIf="!((evalType === evalTitleEnum.MOCA) && (question.maxQuestionValue === -1))"
                    class="form-control shadow-sm p-3 border border-2"
                    [formControlName]="question.questionId?.toString()" [rows]="4"></textarea>
                </div>

                <div *ngIf="question?.type === questionTypeEnum.SINGLE_CHOICE">
                  <ng-container [ngTemplateOutlet]="titleAndName"
                    [ngTemplateOutletContext]="{ question }"></ng-container>
                  <select *ngIf="!((evalType === evalTitleEnum.MOCA) && (question.maxQuestionValue === -1))"
                    class="form-control" [formControlName]="question.questionId?.toString()"
                    (change)="updateQuestionAnswerId($event, question)">
                    <option *ngFor="let option of question?.options" [value]="option.questionAnswerId">{{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </ng-template>

            <ng-template #titleAndName let-question="question">
              <label [for]="question?.questionAnswerId" class="mt-3 h6 fw-bold w-100">{{question?.title || ''}} {{
                question?.title === 'TIMED UP & GO'|| question?.title === 'TUG DUAL TASK' ? "(second)" : ""
                }}</label>
              <p class="mb-3 h5 fw-bolder mb-3 w-100">{{question?.name}}</p>
            </ng-template>

          </div>
          <ng-container *ngIf="evalTitle === 'MINI_BEST'" [ngTemplateOutlet]="minibestquestion">
          </ng-container>
        </ng-container>

      </div>
    </form>
  </div>
  <ng-template #minibestquestion>
    <div class="form-row p-3 mb-2 col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="d-flex justify-content-between">
        <span class="modal-title text-success h3 fw-bold">{{
          questions && questions.length < 10 ? "0" + questions.length : questions.length + 1 || "" }}</span>
            <span class="modal-title mt-3"></span>
      </div>
      <span class="modal-title h6 text-uppercase">{{
        "Assesment" | translate
        }}</span>
      <div>
        <label class="mt-3 h6 fw-bold w-100">DIFFERENZA PERCENTUALE TRA TIME TUG &TIMEDT TUD (%)
        </label>
        <p class="mb-3 h5 fw-bolder mb-3 w-100"></p>
      </div>
      <div>
        <div>
          <input type="number" min="0" id="numericInput"
            onkeydown="return event.keyCode !== 69  && event.keyCode !== 189"
            class="form-control shadow-sm p-3 border border-2"
            [value]="(((this.tugDualTaskValue / this.timeUpAndGoValue) * 100) - 100).toFixed(3)" readonly />
        </div>
      </div>
    </div>
  </ng-template>
  <div class="px-4 py-3">
    <div class="form-group col-md-12 d-flex justify-content-end holder column-gap-3">
      <button
        *ngIf="evalTitle !== 'MINI_BEST' && isAlreadyAnswered && editable && (transformedMetadata || hasStaticReferenceData)"
        type="button" class="btn mb-2 btn-secondary shadow-md" (click)="makeNonEditable()">
        {{'Cancel' | translate}}
      </button>
      <button type="button" class="btn mx-2 mb-2 btn-primary shadow-md" (click)="!editable ? makeEditable() : submit()">
        {{ (!editable ? 'Edit' : 'Submit') | translate }}
      </button>
    </div>
  </div>
</div>
