import { Component, Input, OnInit } from '@angular/core';
import { IFallsDiaryLabel, IFallsDiaryValue } from '../../dto/data/falls-diary.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-falls-diary-item',
  templateUrl: './falls-diary-item.component.html',
  styleUrls: ['./falls-diary-item.component.scss']
})
export class FallsDiaryItemComponent implements OnInit {

  @Input() labels: IFallsDiaryLabel[];
  @Input() fallsDiaryValue: IFallsDiaryValue;
  fallsDiaryMonthValue: string;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const date = new Date(this.fallsDiaryValue.eventDate);
    let month = date.toLocaleString('default', { month: 'short' });
    this.fallsDiaryMonthValue = this.translate.instant(month);
  }

}
