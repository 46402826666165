import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-inclusion-detail-item',
  templateUrl: './inclusion-detail-item.component.html',
  styleUrls: ['./inclusion-detail-item.component.scss']
})
export class InclusionDetailItemComponent implements OnInit {
// Interfaces and DTOs can be fixed once API design is confirmed
  @Input()
  inclusionDetail: any;
  @Input()
  inclusionDetails: any;
  @Input()
  index: number;
  @Input()
  even: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
