<div class="modal-header">
    <h2 class="modal-title">{{'ChangePassword' | translate}}</h2>
</div>
<div class="modal-body">
    <form [formGroup]="formPassword">
        <div class="form-row">
            <div class="form-floating mb-3 me-3 col-md-5 w-100">
                <input class="form-control shadow-sm px-4 border-2 " type="password"
                    placeholder="Enter Current Password" id="oldPassword" required autocomplete="new-oldPassword"
                    formControlName="oldPassword"
                    [class.is-invalid]="formSubmitted && formPassword.get('oldPassword')?.invalid">
                <label for="oldPassword"> {{'CurrentPassword' | translate}}</label>
                <div *ngIf="formSubmitted && formPassword.get('oldPassword')?.invalid " class="alert">
                    <small class="text-danger" *ngIf="formPassword.get('oldPassword')?.errors?.['required']">
                        {{'OldPasswordRequired' | translate}}
                    </small>
                </div>
            </div>
            <div class="form-floating mb-3 me-3 col-md-5 w-100">
                <input class="form-control shadow-sm px-4 border-2 " type="password" placeholder="Enter New Password"
                    id="newPassword" required autocomplete="new-newPassword" formControlName="newPassword"
                    [class.is-invalid]="formSubmitted && formPassword.get('newPassword')?.invalid">
                <label for="newPassword"> {{'NewPassword' | translate}}</label>
                <div *ngIf="formSubmitted && formPassword.get('newPassword')?.invalid " class="alert">
                    <small class="text-danger" *ngIf="formPassword.get('newPassword')?.errors?.['required']">
                        {{'NewPasswordRequired' | translate}}
                    </small>
                </div>
            </div>
            <div class="form-floating mb-3 me-3 col-md-5 w-100">
                <input class="form-control shadow-sm px-4 border-2 " type="password" placeholder="Confirm Password"
                    id="confirmPassword" required autocomplete="new-confirmPassword" formControlName="confirmPassword"
                    [class.is-invalid]="formSubmitted && formPassword.get('confirmPassword')?.invalid">
                <label for="confirmPassword"> {{'ConfirmPassword' | translate}}</label>
                <div *ngIf="formSubmitted && formPassword.get('confirmPassword')?.invalid " class="alert">
                    <small class="text-danger" *ngIf="formPassword.get('confirmPassword')?.errors?.['required']">
                        {{'ConfirmPasswordRequired' | translate}}
                    </small>
                    <small class="text-danger"
                        *ngIf="formPassword.get('confirmPassword')?.errors?.['confirmPasswordValidator']">
                        {{'PassswordNotMatch' | translate}}
                    </small>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="row">
    <div class="d-grid gap-2 d-flex justify-content-between">
        <button class="btn btn-secondary m-4" type="button" (click)="activeModal.dismiss('Cross click')">{{'Cancel' | translate}}</button>
        <button class="btn btn-primary m-4" type="button" (click)="savePassword()">{{'ChangePassword' | translate}}</button>
    </div>
</div>