import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/component/login/login.component';
import { AuthGuard } from './core/guard/auth.guard';

/*** CAUTION: DO NOT MODIFY !!!
 * Please do not modify this file (if this is a compiled .ts file) after compilion as it will be generated dynamically from src/app/templates/*,
 * If you really need to make changes to this file, consider changing files in src/app/templates/ directory.
 */

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'doctor',
    canActivate: [AuthGuard],
    loadChildren: () => import('./doctor/doctor.module').then(m => m.DoctorModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
