import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'net-medico';

  constructor(translate: TranslateService) {
    let [userLanguage] = navigator.language?.split('-');
    translate.addLangs(['en', 'it']);
    translate.setDefaultLang(userLanguage);
    translate.use(userLanguage);
  }
}
