<div class="modal-body">
    <div>
        <h2>{{'CreateAppointment' | translate}}</h2>
        <p>{{'CreateYourAppointment' | translate}}</p>
    </div>
    <form [formGroup]="createAppointmentForm">
        <div class="box">
            <label for="patientName">{{'Patient' | translate}}</label>
            <select id="patientName" formControlName="patientId">
                <option selected disabled hidden value="0">{{'Selectpatient' | translate }}</option>
                <option *ngFor="let patient of patientList" [ngValue]="patient.id">{{patient.name}}
                </option>
            </select>
        </div>
        <div class="box">
            <label for="apptDate">{{'Date' | translate}}</label>
            <input type="date" id="apptDate" formControlName="appointmentDate">
        </div>
        <div class="box">
            <label for="apptTime">{{'Time' | translate}}</label>
            <input type="time" id="apptTime" formControlName="appointmentTime">
        </div>
        <div class="p-10-0">
            <div class="form-group col-md-12 d-flex justify-content-between holder">
                <button type="button" class="btn mb-2 btn-secondary shadow-sm"
                    (click)="activeModal.dismiss('Cross click')">
                    {{'Back' | translate}}
                </button>
                <button type="button" class="btn mb-2 btn-success" ngbAutofocus *ngIf="action == 'add'"
                    (click)="createAppt()">
                    {{'Create' | translate}}
                </button>
                <button type="button" class="btn mb-2 btn-success" ngbAutofocus *ngIf="action == 'edit'"
                    (click)="modifyAppointment()">
                    {{'Update' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>
