import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, asyncScheduler, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '../../core/service/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from 'src/app/shared/enum/error-messages.enum';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private logger: LoggerService, private router: Router, private route: ActivatedRoute, private authService: AuthenticationService, private translate: TranslateService,) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
            this.logger.debug('HttpErrorInterceptor', 'Error', error.status, error);
            let errMsg = error.message;
            switch (error.status) {
                case 401:
                    this.broadcastUnauthorised();
                    this.authService.clearSession();
                    this.router.navigate(['']).catch((err: Error) => errMsg = err.message);
                    asyncScheduler.schedule(() => {
                        window.location.reload();
                    }, 300);
                    break;
                case 400:
                case 500:
                    errMsg = this.getErrorMessage(error);
                    break;
                case 422:
                    errMsg = this.getErrorMessage(error);
                    break;
                case 409:
                    errMsg = this.getErrorMessage(error);
                    break;
                case 0:
                    errMsg = `${this.translate.instant('UnableToConnect')}`;
                    break;
            }
            return throwError(() => new Error(errMsg));
        }));
    }

    broadcastUnauthorised(): void {
        if (window.vuplex) {
            this.broadcastMessage('401');
        } else {
            window.addEventListener('vuplexready', () => this.broadcastMessage('401'));
        }
    }

    broadcastMessage(message): void {
        window.vuplex.postMessage({ type: message, message });
    }

    getErrorMessage(error: HttpErrorResponse): string {
        if (error.error?.message instanceof Array) {
            return error.error?.message?.join(', ');
        }
        if (error?.error?.message?.startsWith(ErrorMessage.EMAIL_FAILED)) {
            return `${this.translate.instant('SendingEmailFailed')} ${error?.error?.error}`;
        }
        if (error.error?.message.startsWith(ErrorMessage.INVALID_WEEK_START_DATE)) {
            return this.translate.instant('InvalidWeek');
        }
        return error?.error?.message;
    }
}
