import { Injectable } from '@angular/core';
import urlcat, { join } from 'urlcat';
import { environment } from '../../../environments/environment';

@Injectable()
export class EnvironmentService {

    private readonly host: any;
    private endpoints: any;

    constructor() {
        this.host = environment.api.host;
        this.endpoints = environment.api;
    }

    /**
     * Retrieves the relative endpoint.
     * Checks if attribute exist.
     * @returns the relative url string
     * @param endpoint
     * @deprecated
     */
    public getApiEndpoint(endpoint: string): string {
        if (!this.endpoints.hasOwnProperty(endpoint)) {
            throw new Error(`API '${endpoint}' not exist on environment.api.endpoints configuration object.`);
        }
        return `${this.host}/${this.endpoints[endpoint].value}`;
    }

    /**
     * Retrieves the relative endpoint.
     * Checks if attribute exist.
     * @returns the relative url string
     * @param endpoint
     * @param child
     * @param sub
     * @deprecated
     */
    public getApiEndpointChildren(endpoint: string, child: string, sub: string | null = null): string {
        if (!this.endpoints.hasOwnProperty(endpoint)) {
            throw new Error(`API '${child}' not exist on environment.api.endpoints.${endpoint}.children configuration object.`);
        }
        return sub !== null ?
            // eslint-disable-next-line max-len
            `${this.host}/${this.endpoints[endpoint].value}/${this.endpoints[endpoint].children[child].value}/${this.endpoints[endpoint].children[child].children[sub].value}` :
            `${this.host}/${this.endpoints[endpoint].value}/${this.endpoints[endpoint].children[child].value}`;
    }

    /**
     * Return true if environment is production, otherwise false.
     */
    get isProduction(): boolean {
        return environment.production;
    }

    /**
     * Returns url pacthed with template variables
     * @param urlTemplate URL containing template variables. eg. '/users/:id'
     * @param urlParams Object containing values for template variables. eg. { id: 123 }
     * @param apiVersion
     * @returns Resolved url as string
     * @example getApiUrl('users/:id', { id: 123, search: 'foo' }) => 'http://api.example.com/users/123'
     */
    public getApiUrl(urlTemplate: string, urlParams: { [key: string]: any } = {}, apiVersion: string | null = 'v1'): string {
        return urlcat(join(this.host, '/', apiVersion || ''), urlTemplate, urlParams);
    }

    getHost() {
      return this.host;
    }
}
