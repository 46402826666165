import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {IError} from '../dto/error.interface';
import {Injectable} from '@angular/core';

@Injectable()
export abstract class AbstractService<T> {

    /**
     * Useful method to throw default error
     */
    protected getDefaultError(): IError {
        return {
            status: 'ERROR',
            message: 'Ops! Abbiamo risconstrato un errore imprevisto.',
            errors: new Array<any>()
        } as IError;
    }

    /**
     * Service Error Handler that returns an RxJS ErrorObservable with a user-friendly error message.
     * @param error: current error
     * @return user-friendly error message
     */
    protected handleError = (error: HttpErrorResponse) => {
        const iError: IError = this.getDefaultError();
        if (!!error.error) {
            iError.status = error.error.status;
            iError.errors = error.error.errors;
            if (error && error.error && error.error.message) {
                iError.message = error.error.message;
            }
            if (Array.isArray(iError.errors) && iError.errors.length !== 0) {
                iError.message = iError.errors[0].message;
            }
        }
        console.error(`Backend returned code ${iError.status}, `, `body was: ${iError.message}`, error);
        // return an observable with a user-facing error message;
        return throwError(iError);
    };
}
