import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../core/service/logger.service';
import { DateTime, ToObjectOutput } from "luxon";

@Injectable()
export class UtilsService {

  private static readonly DATE_FORMAT: string = 'DD-MM-YYYY';

  constructor(
    private loggerService: LoggerService
  ) {
    this.loggerService.info('System retrieved: ' + this.getOS());
  }

  /**
   * NgbDataStruct Formatter
   * This method is the second method called when user click the date picker.
   * Receives an input string and return the date in a valid NgbDateStruct object,
   * otherwise return null.
   * Used by {@link NgbMomentDateParserFormatterService} and {@link NgbMomentDateAdapterService }
   */
  fromStringToNgbDateStruct(value: string, format: string = 'dd-MM-yyyy'): NgbDateStruct | null {
    const date: DateTime = DateTime.fromFormat(value, format);
    if (value === null || !date.isValid) {
      return null;
    }
    this.loggerService.debug('Util Service', 'From String to NgbDateStruct', 'VALUE', value);
    const momentObjectOutput: ToObjectOutput = date.toObject();
    const ngbDateStruct: NgbDateStruct = {
      year: momentObjectOutput.year,
      month: this.fixNgbDateStructMonths(momentObjectOutput.month || 1),
      day: momentObjectOutput.day
    } as NgbDateStruct;
    this.loggerService.debug('Util Service', 'From String to NgbDateStruct', 'RESULT', ngbDateStruct);
    return ngbDateStruct || null;
  }

  /**
   * String Formatter
   * This method is the third method called when user click the date picker.
   * Receives an input object with day, month and year selected and return the date in a valid formatted string,
   * otherwise return an empty string.
   * Used by {@link NgbMomentDateParserFormatterService }
   */
  fromNgbDateStructToString(value: NgbDateStruct | null): string | null {
    if (value === null) {
      return '';
    }
    this.loggerService.debug('Util Service', 'From NgbDateStruct to String', 'VALUE', value);
    const toObjectOutput: ToObjectOutput = {
      day: value.day,
      month: this.fixMomentMonths(value.month),
      year: value.year
    } as ToObjectOutput;
    const date: DateTime = DateTime.fromObject(toObjectOutput)
    const result: string = date.isValid ? date.toFormat(UtilsService.DATE_FORMAT) : '';
    this.loggerService.debug('Util Service', 'From NgbDateStruct to String', 'RESULT', result);
    return result;
  }

  /**
   * ISOString Adapter
   * This method is the first method called when user click the date picker.
   * Receives an input object with day, month and year selected and return the date in a valid formatted ISOString,
   * otherwise return an empty string.
   * Used by {@link NgbMomentDateAdapterService }
   */
  fromNgbDateStructToISOString(value: NgbDateStruct | null): string | null {
    if (value === null) {
      return '';
    }
    this.loggerService.debug('Util Service', 'From NgbDateStruct to ISO String', 'VALUE', value);
    const momentObjectOutput: ToObjectOutput = {
      day: value.day,
      month: this.fixMomentMonths(value.month),
      year: value.year
    } as ToObjectOutput;
    const date: DateTime = DateTime.fromObject(momentObjectOutput);
    this.loggerService.debug('Util Service', 'From NgbDateStruct to ISO String', 'RESULT', date.toISO());
    return date.isValid ? date.toISO() : '';
  }

  /**
   * Useful method to get the current period
   * @param date
   */
  getCurrentQuarter(date: DateTime | null = null): number | null {
    date = (date !== null) ? date : DateTime.now();
    const month: number = date.month;
    switch (month) {
      case 0:
      case 1:
      case 2:
        return 1;
      case 3:
      case 4:
      case 5:
        return 2;
      case 6:
      case 7:
      case 8:
        return 3;
      case 9:
      case 10:
      case 11:
        return 4;
      default:
        return null;
    }
  }

  /**
   * Useful method to format number into currency
   * @param value
   * @param locales
   * @param options
   */
  formatNumberAsCurrency(value: number, locales: string = 'it-IT', options: any = { style: 'currency', currency: 'EUR' }): string {
    const formatter = new Intl.NumberFormat(locales, options);
    return formatter.format(value);
  }

  // MomentJS accepts numbers from 0 to 11.
  private fixMomentMonths(month: number): number {
    switch (month) {
      case 1: return 0;
      case 2: return 1;
      case 3: return 2;
      case 4: return 3;
      case 5: return 4;
      case 6: return 5;
      case 7: return 6;
      case 8: return 7;
      case 9: return 8;
      case 10: return 9;
      case 11: return 10;
      case 12: return 11;
    }
    return -1;
  }

  // NgbDateStruct accepts numbers from 1 to 12.
  private fixNgbDateStructMonths(month: number): number {
    switch (month) {
      case 0: return 1;
      case 1: return 2;
      case 2: return 3;
      case 3: return 4;
      case 4: return 5;
      case 5: return 6;
      case 6: return 7;
      case 7: return 8;
      case 8: return 9;
      case 9: return 10;
      case 10: return 11;
      case 11: return 12;
    }
    return -1;
  }

  /**
   * Get full name
   */
  fullName(user: { firstName: string, lastName: string } | null = null) {
    return user ? `${user.firstName || ''} ${user.lastName || ''}` : '';
  }

  /**
   * Get age from date of birth
   */
  getAgeFromDateOfBirth(dateString: string) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  /**
   * Get Greeting Message
   */
  greetingMessage() {
    let today = new Date();
    let hours = today.getHours();
    if (hours < 12) {
      return "GoodMorning";
    } else if (hours < 18) {
      return "GoodAfternoon";
    } else {
      return "GoodEvening";
    }
  }

  getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os: string | null = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

}
