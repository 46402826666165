import { Component, Input, OnInit } from '@angular/core';
import { DoctorService } from 'src/app/services/http/doctor.service';
import { ILoggedInUser } from '../../dto/logged-in-user.interface';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { ToastService } from '../../../services/component/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAppointmentSaveData, IAppointmentUpdateData } from '../../dto/data/getPatientList.interface';
import { IPatientAppointmentDetail } from '../../dto/data/clients.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-appointment-modal',
  templateUrl: './create-appointment-modal.component.html',
  styleUrls: ['./create-appointment-modal.component.scss']
})
export class CreateAppointmentModalComponent implements OnInit {

  loggedInUser: ILoggedInUser | null;
  patientList = new Array();
  doctorId: number;
  createAppointmentForm = new FormGroup({
    patientId: new FormControl(0, [Validators.required]),
    appointmentDate: new FormControl('', [Validators.required]),
    appointmentTime: new FormControl('', [Validators.required])
  })
  @Input() data: any;
  @Input() action: string;
  @Input() time: string;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private doctorService: DoctorService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getLoggedInUser();
    this.getPatientList();
    if (this.action == 'edit')
      this.init()
  }

  getLoggedInUser(): void {
    this.authenticationService.user.subscribe({
      next: (user: ILoggedInUser | null) => {
        this.loggedInUser = user;
        this.doctorId = this.loggedInUser?.id as number;
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    });
  }

  init() {
    this.createAppointmentForm.patchValue({
      patientId: this.data.patientId,
      appointmentDate: new Date(this.time).toISOString().substring(0, 10),
      appointmentTime: new Date(this.time).toLocaleTimeString('en-US', { hour12: false })
    });
    this.createAppointmentForm.controls['patientId'].disable();
  }

  getPatientList() {
    this.doctorService.getPatientList(this.doctorId).subscribe({
      next: (result: any) => {
        const resultArr = result;
        resultArr.map((ele) => {
          let data = {
            id: ele.id,
            name: ele.firstName + " " + ele.lastName
          }
          this.patientList.push(data);
        });
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    })
  }

  modifyAppointment() {
    let data: IAppointmentUpdateData = {
      datetime: this.formatDateTime(this.createAppointmentForm.value.appointmentDate, this.createAppointmentForm.value.appointmentTime)
    };
    this.doctorService.updateAppointment(this.doctorId, this.data.patientId, this.data.appointmentId, data).subscribe({
      next: (result: any) => {
        this.toastService.showSuccess('UpdatedSuccessfully');
        this.modalService.dismissAll({ response: true });
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    });
  }

  createAppt() {
    let data: IAppointmentSaveData = {
      patientId: Number(this.createAppointmentForm.value.patientId),
      datetime: this.formatDateTime(this.createAppointmentForm.value.appointmentDate, this.createAppointmentForm.value.appointmentTime)
    };
    this.doctorService.saveAppointmentData(this.doctorId, data).subscribe({
      next: (result: any) => {
        this.toastService.showSuccess('SavedSuccessfully');
        this.modalService.dismissAll({ response: true });
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    });
  }

  formatDateTime(date, time) {
    let datetime = date + " " + time;
    return new Date(datetime).toISOString().substring(0, 16) + "Z";
  }

}
