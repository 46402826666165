import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/core/service/theme.service';
import { ToastService } from 'src/app/services/component/toast.service';
import { ILoggedInUser } from 'src/app/shared/dto/logged-in-user.interface';
import { AuthenticationService } from '../../service/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    @ViewChild('dangerTpl', { read: TemplateRef }) dangerTpl: TemplateRef<any>;

    formSubmitted: boolean = false;
    title: string;
    form: UntypedFormGroup;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastService: ToastService,
        private theme: ThemeService,
        private translate: TranslateService
    ) {
        this.title = this.translate.instant('NET');
        this.theme.current = this.theme.buildType?.toLowerCase();
        this.form = new UntypedFormGroup({
            username: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(50),
                Validators.email
            ]),
            password: new UntypedFormControl('', [Validators.required,
            Validators.minLength(6),
            Validators.maxLength(128)])
        });

        const user = this.authenticationService.user.value;
        if (user?.role) {
            this.theme.current = user?.role?.toLowerCase();
            this.theme.role = user?.role;
            this.router.navigateByUrl(`/${user?.role?.toLowerCase()}/dashboard`).catch((err: Error) => this.toastService.showDanger(err.message));
        } else {
            this.authenticationService.clearSession();
            this.router.navigate(['']).catch((err: Error) => this.toastService.showDanger(err.message));
        }
    }

    async login(_event: Event): Promise<void> {
        this.formSubmitted = true;
        const { username, password } = this.form.value;
        if (this.form.valid) {
            this.authenticationService.login(username, password, true).subscribe({
                next: ({ role }: ILoggedInUser) => {
                    if (role) {
                        this.theme.current = role?.toLowerCase();
                        this.theme.role = role;
                        this.router.navigateByUrl(`/${role?.toLowerCase()}/dashboard`).catch((err: Error) => this.toastService.showDanger(err.message));
                    } else {
                        this.toastService.showDanger(this.dangerTpl);
                    }
                },
                error: (err: Error) => this.toastService.showDanger(this.dangerTpl)
            })
        }
    }

    showDanger(dangerTp: TemplateRef<any> | string) {
        this.toastService.show(dangerTp, { classname: 'bg-danger text-light', delay: 15000 });
    }
}
