import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { EnvironmentService } from 'src/app/core/service/environment.service';
import { BreadcrumbService } from 'src/app/services/component/breadcrumb.service';
import { ThemeService } from 'src/app/core/service/theme.service';
import { ToastService } from 'src/app/services/component/toast.service';
import { IDoctor } from 'src/app/shared/dto/data/doctor.interface';
import { ILoggedInUser } from 'src/app/shared/dto/logged-in-user.interface';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { Breadcrumb } from 'src/app/shared/model/component/breadcrumb.model';
import { DoctorService } from 'src/app/services/http/doctor.service';
import { ConfirmPasswordValidator } from '../../validator/confirm-password.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  formSubmitted: boolean = false;
  form: UntypedFormGroup;
  breadcrumb: any;
  view = true;
  formPassword: UntypedFormGroup;
  loggedInUser: ILoggedInUser | null;
  doctorId: number;
  passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;

  constructor(
    public theme: ThemeService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private doctorService: DoctorService,
    private fb: FormBuilder
  ) {

    this.view = this.theme.role !== UserRoleEnum.PATIENT;

    this.activatedRoute.data.forEach((data) => {
      this.breadcrumb = new Breadcrumb(data['breadcrumb'], this.router.url);
      this.breadcrumbService.add(this.breadcrumb);
    });
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      specialization: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      age: new UntypedFormControl(''),
    });
    this.formPassword = this.fb.group({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(this.passwordRegex)]),
      confirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(this.passwordRegex)])
    },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirmPassword")
      });
  }

  get specializations(): Observable<Array<{ id: string, name: string }>> {
    return of([
      {
        id: "Fisioterapista",
        name: "Fisioterapista",
      },
      {
        id: "Neurologo",
        name: "Neurologo",
      },
      {
        id: "Fisiatra",
        name: "Fisiatra",
      },
    ]);
  }

  ngOnInit(): void {
    this.authenticationService.user.subscribe({
      next: (user: ILoggedInUser | null) => {
        this.loggedInUser = user;
        this.doctorId = this.loggedInUser?.id as number;
        if (user) {
          this.getDoctorProfile();
        }
      },
      error: (err: Error) => this.toastService.showDanger(err?.message)
    });
  }

  getDoctorProfile(): void {
    this.doctorService.getDoctorProfile(this.doctorId).subscribe({
      next: (result: IDoctor) => {
        if (result) {
          this.form.patchValue(result);
        }
      },
      error: (err: Error) => this.toastService.showDanger(err?.message)
    })
  }

  save(): void {
    this.formSubmitted = true;
    if (this.form.valid) {
      const url = this.environmentService.getApiUrl('doctors/:doctorId', { doctorId: this.doctorId });
      const { firstName, lastName, specialization } = this.form.value;
      this.httpClient.put(url, { firstName, lastName, specialization }).subscribe({
        next: (response) => {
          const updatedUser = { ...this.loggedInUser, ...response } as ILoggedInUser;
          this.authenticationService.updateLoggedInUserState(updatedUser);
          this.view = true;
          this.form.get('email')?.enable();

          if (this.theme.role === UserRoleEnum.PATIENT) {
            this.router.navigateByUrl('patient/profile');
          }
        },
        error: (err: Error) => this.toastService.showDanger(err?.message)
      })
    } else {
      // this.toastService.showDanger('Your form has some errors, please fix them first!');
      this.toastService.showDanger('FormError');
    }
  }

  changePassword(content: any) {
    this.modalService.open(content, { centered: true });
  }

  savePassword() {
    this.formSubmitted = true;
    if (this.formPassword.valid) {
      const formData = this.formPassword.value;
      this.doctorService.saveDoctorPassword(this.doctorId, formData).subscribe({
        next: () => {
          this.modalService.dismissAll();
          this.router.navigate(['']).catch((err: Error) => this.toastService.showDanger(err?.message))
        },
        error: (err: Error) => this.toastService.showDanger("Must enter valid old password")
      })
    } else {
      this.toastService.showDanger('FormError');
    }
  }

  editProfile() {
    this.view = false;
    this.form.controls['email'].disable();
  }

  ngOnDestroy(): void {
    this.breadcrumbService.remove(this.breadcrumb);
  }
}
