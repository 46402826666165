<div class="modal-container d-flex flex-column">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body px-4 pt-0">
        <div class="form-row">
            <div class="col-sm-4">
                <h4>{{type}}</h4>
            </div>
            <div class="col-sm-8">
                <div class="btn-group" role="group">
                    <input type="radio" (click)='isSelected = 1' class="btn-check" name="btnradio" id="true"
                        autocomplete="off" checked>
                    <label class="btn btn-primary m-2 rounded" [class.btn-primary]='isSelected === 1'
                        [class.btn-secondary]='isSelected !== 1' for="true">
                        {{'True'| translate}}
                    </label>

                    <input type="radio" (click)='isSelected = 2' class="btn-check" name="btnradio" id="false"
                        autocomplete="off">
                    <label class="btn btn-primary m-2 rounded" [class.btn-primary]='isSelected === 2'
                        [class.btn-secondary]='isSelected !== 2' for="false">
                        {{'False'| translate}}
                    </label>

                    <input type="radio" (click)='isSelected = 3' class="btn-check" name="btnradio" id="none"
                        autocomplete="off">
                    <label class="btn m-2 rounded" [class.btn-primary]='isSelected === 3'
                        [class.btn-secondary]='isSelected !== 3' for="none">
                        {{'None'| translate}}
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="px-4 pb-4">
        <div class="form-group col-md-12 d-flex justify-content-end holder">
            <button type="button" class="btn mb-2 btn-primary shadow-sm" ngbAutofocus (click)="submit()">
                {{'Finish'| translate}}
            </button>
        </div>
    </div>
</div>