import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/service/environment.service';
import { IAppointmentDetails, IAppointments, IPatient, IPatientDetails, IPatientSessionAutoclose, IPatientSessionRecover, IPatientSessionResponse, IRescheduleBody } from 'src/app/shared/dto/data/clients.interface';
import { IPassword } from 'src/app/shared/dto/data/password.interface';
import {SessionResponseDto} from "./model/sessionResponseDto";
import {PatientResponseDto} from "./model/patientResponseDto";
import {DayAppointmentResponseDto} from "./model/dayAppointmentResponseDto";
import {AppointmentResponseDto} from "./model/appointmentResponseDto";
import {PatientSessionPlanningResponseDto} from "./model/patientSessionPlanningResponseDto";
import {SessionSkipResponseDto} from './model/sessionSkipResponseDto';
import {ResetWeekRequestDto} from "./model/resetWeekRequestDto";

@Injectable({ providedIn: 'root' })
export class PatientService {

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  getPatientProfile(patientId: number,todayDateStr:string): Observable<PatientResponseDto> {
    const url = this.environmentService.getApiUrl('patients/:patientId', { patientId, todayDateStr, timestamp: Date.now() });
    return this.http.get<PatientResponseDto>(url);
  }

  savePatientPassword(patientId: number, formData: IPassword): Observable<void> {
    const { oldPassword, newPassword, confirmPassword } = formData;
    const url = this.environmentService.getApiUrl('patients/:patientId/password', { patientId });
    return this.http.patch<void>(url, { oldPassword, newPassword, confirmPassword });
  }

  getPatientSession(patientId: number,todayDateStr:string, pathology: string): Observable<SessionResponseDto[]> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions', { patientId }) + `?pathology=${pathology}&todayDateStr=${todayDateStr}`;
    return this.http.get<SessionResponseDto[]>(url);
  }

  reschedulePatientSession(patientId: number, on: string, week: boolean, formData: any): Observable<PatientSessionPlanningResponseDto> {
    const url = this.environmentService.getApiUrl('patients/:patientId/reschedule', { patientId, on, week });
    return this.http.post<any>(url, formData);
  }

  resetPatientWeek(patientId: number, weekNumber: number, formData: ResetWeekRequestDto): Observable<any> {
    const url = this.environmentService.getApiUrl('patients/:patientId/week/:weekNumber/reset', { patientId, weekNumber });
    console.log('URL', url);
    return this.http.post<any>(url, formData);
  }

  patchPatientSessionStart(patientId: number, sessionId: number, todayDateStr:string, formData: any, platform:string ): Observable<any> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions/:sessionId/start', { patientId, sessionId, platform });
    return this.http.patch<void>(url, formData);
  }

  getPatientUpcomingAppointments(patientId: number, date: string): Observable<AppointmentResponseDto[]> {
    const url = this.environmentService.getApiUrl('patients/:patientId/appointments/upcoming', { patientId, date });
    return this.http.get<AppointmentResponseDto[]>(url);
  }

  getPatientPastAppointments(patientId: number, date: string): Observable<AppointmentResponseDto[]> {
    const url = this.environmentService.getApiUrl('patients/:patientId/appointments/past', { patientId, date });
    return this.http.get<AppointmentResponseDto[]>(url);
  }

  patchPatientSessionsAutoclose(patientId: number, sessionId: number): Observable<IPatientSessionAutoclose> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions/:sessionId/autoclose', { patientId, sessionId });
    return this.http.patch<IPatientSessionAutoclose>(url, null);
  }

  patchPatientSessionsSkip(patientId: number, sessionId: number): Observable<SessionSkipResponseDto> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions/:sessionId/skipped', { patientId, sessionId });
    return this.http.patch<SessionSkipResponseDto>(url, null);
  }

  getPatientSessionRecover(patientId: number, sessionId: number, todayDateStr: string, pathology: string): Observable<PatientSessionPlanningResponseDto> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions/:sessionId/recover', { patientId, sessionId }) + `?todayDateStr=${todayDateStr}&pathology=${pathology}`;
    return this.http.get<PatientSessionPlanningResponseDto>(url);
  }


  patchPatientSessionScore(patientId: number, sessionId: number, formData: object): Observable<IPatientSessionAutoclose> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions/:sessionId/score', { patientId, sessionId });
    return this.http.patch<IPatientSessionAutoclose>(url, formData);
  }

  patchPatientSessionsCompleted(patientId: number): Observable<PatientResponseDto> {
    const url = this.environmentService.getApiUrl('patients/:patientId/sessions/completed', { patientId });
    return this.http.patch<PatientResponseDto>(url, null);
  }
}
