<div class="survey-item">

  <div class="text">
    <p class="m-0">
      {{item?.description}}
    </p>
  </div>


  <div aria-label="Basic radio toggle button group" class=" w-100 btn-group-lg" role="group">
    <input (click)="item.opted = pathologyCriteriaOptedEnum.YES" [id]="'yes' + item.id" [name]="'btnRadio' + item.id"
      autocomplete="off" class="btn-check" type="radio" [disabled]="isAgeSurveyDisabled"
      [checked]="isAgeSurveyDisabled && (isElder == true)">
    <label [for]="'yes' + item.id" class="btn btn-primary m-2 primary-semi-transparent rounded btn-toggle">{{'Yes' |
      translate}}</label>
    <input (click)="item.opted = pathologyCriteriaOptedEnum.NO" [id]="'no' + item.id" [name]="'btnRadio' + item.id"
      autocomplete="off" class="btn-check" type="radio" [disabled]="isAgeSurveyDisabled"
      [checked]="isAgeSurveyDisabled && (isElder == false)">
    <label [for]="'no' + item.id" class="btn btn-danger m-2 danger-semi-transparent rounded btn-toggle">{{'No' |
      translate}}</label>
  </div>


</div>