import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {NgbDateAdapter, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AuthenticationService} from './core/service/authentication.service';
import {ServicesModule} from './services/services.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CustomNgbDateAdapter, CustomNgbDateParserFormatter} from './custom-ngb-date';
import {SharedModule} from "./shared/shared.module";

const LOCATION_STRATEGY = {
  provide: LocationStrategy,
  useClass: HashLocationStrategy
};

registerLocaleData(localeIt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    ServicesModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    LOCATION_STRATEGY,
		{ provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.verify();
  }
}
