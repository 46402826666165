import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterBy',
    pure: false
})

export class FilterByPipe implements PipeTransform {
    transform(items: any[], prop: string, term: string, negate: boolean = false, exact: boolean = false): any[] {

        const strTerm: string = `${term}`.toLowerCase();
        if (!Array.isArray(items) || !term) {
            return items;
        }

        return items.filter((obj: any) => {
            const value: string = this.getProperty(obj, prop);

            if (!value) {
                return false;
            }

            const strValue: string = `${value}`.toLowerCase();

            if (negate) {
                return exact
                    ? strTerm !== strValue
                    : strValue.indexOf(strTerm) === -1;
            }

            return exact
                ? strTerm === strValue
                : strValue.indexOf(strTerm) !== -1;
        });
    }

    getProperty(obj: any, path: string): string {
        if (obj == null || obj.constructor !== Object) {
            return '';
        }

        const replaced: string = path.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '');
        const keys: string[] = replaced.split('.');
        let result: string = obj[keys.shift()!];

        for (const key of keys) {
            if (result == null || result.constructor !== Object) {
                return '';
            }

            result = result[key];
        }

        return result;
    }
}
