export enum EvaluationSurveyTypeEnum {
  ABC = 'ABC',
  CMI = 'CMI',
  FES = 'FES',
  FOG = 'FOG',
  MMSE = 'MMSE',
  MOCA = 'MOCA',
  SF36 = 'SF36',
  NFOG = 'NFOG',
  SPPB = 'SPPB',
  TWO_MWT = 'TWO_MWT',
  H_and_Y = 'H_and_Y',
  MINI_BEST = 'MINI_BEST',
  FOUR_STEPS = 'FOUR_STEPS',
  FLUENZA_VERBALE_FONEMICA = 'FLUENZA_VERBALE_FONEMICA',
  FLUENZA_VERBALE_SEMANTICA = 'FLUENZA_VERBALE_SEMANTICA',
  PD_CRS = 'PD_CRS',
}

export enum QuestionTypeEnum {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  PLAIN_TEXT_VALUE = 'PLAIN_TEXT_VALUE',
  PLAIN_NUMBER_VALUE = 'PLAIN_NUMBER_VALUE',
}

export enum EvalTitleEnum {
  MOCA = 'MOCA',
  FOUR_STEPS = 'FOUR_STEPS',
  H_and_Y = 'H_and_Y',
  FLUENZA_VERBALE_FONEMICA = 'FLUENZA_VERBALE_FONEMICA',
  FLUENZA_VERBALE_SEMANTICA = 'FLUENZA_VERBALE_SEMANTICA',
}
export enum StaticReferenceKey {
  FLUENZ_VERBALE_FINAL_VALUES_FONEMICA = "FLUENZ_VERBALE_FINAL_VALUES_FONEMICA",
  FLUENZ_VERBALE_FINAL_VALUES_SEMANTICA = "FLUENZ_VERBALE_FINAL_VALUES_SEMANTICA"
}
