import {LowerCasePipe} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy, PipeTransform} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ToastService} from 'src/app/services/component/toast.service';
import {SortColumn, SortDirection} from 'src/app/shared/directive/sort.directive';
import {IPatient} from 'src/app/shared/dto/data/clients.interface';
import {ILoggedInUser} from 'src/app/shared/dto/logged-in-user.interface';
import {EnvironmentService} from '../../core/service/environment.service';
import {LoggerService} from "../../core/service/logger.service";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import * as _ from 'lodash';
import {di} from "@fullcalendar/core/internal-common";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

@Injectable({ providedIn: 'root' })
export class PatientService implements OnDestroy {
  private subject: BehaviorSubject<IPatient | null>;
  private rescheduler: BehaviorSubject<'WEEK' | 'TRIAL' | null>;
  private _notifications: Subject<'FALLS_DIARY' | null>;

  readonly FALLS_DIARY_KEY = 'PATIENTS_FALLS_DIARY';

  loggedInUser: ILoggedInUser;
  patientsList: IPatient[] = [];
  refreshPatientList = new BehaviorSubject<boolean>(false);

  _state: State = {
    page: 1,
    pageSize: 7,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  private subscription = new Subscription();

  constructor(
    private pipe: LowerCasePipe,
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
    private toastService: ToastService,
    private logger: LoggerService,
  ) {
    this.subject = new BehaviorSubject<IPatient | null>(null);
    this.rescheduler = new BehaviorSubject<'WEEK' | 'TRIAL' | null>(null);
    this._notifications = new Subject<'FALLS_DIARY' | null>();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get state(): State {
    return this._state;
  }

  reschedule(value: 'WEEK' | 'TRIAL' | null) {
    this.rescheduler.next(value);
  }

  get rescheduled ()  {
    return this.rescheduler.asObservable();
  }

  notify(value: 'FALLS_DIARY' | null) {
    this._notifications.next(value);
  }

  get notifications() {
    return this._notifications.asObservable();
  }

  set state(state: State) {
    this._state = { ...this._state, ...state };
  }

  getPatientsByDoctorId(doctorId: number, todayDateStr: string, pageStart: number): Observable<IPatient[]> {
    const { pageSize, page } = this._state;
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients', { doctorId, todayDateStr, start: pageStart, size: pageSize }) ;
    return this.httpClient.get<IPatient[]>(url, {})
      .pipe(
        map(
          (response) => {
            this.patientsList = response;
            return response;
          },
          catchError((err: HttpErrorResponse) => err.message))
      );
  }


  showError(err: Error): void {
    this.toastService.show(err?.message, { classname: 'bg-danger text-light', delay: 15000 })
  }

  compare(v1: string | number, v2: string | number) { return v1 < v2 ? -1 : v1 > v2 ? 1 : 0 };

  sort(patients: IPatient[], column: SortColumn, direction: string): IPatient[] {
    if (direction === '' || column === '') {
      return patients;
    } else {
      return [...patients].sort((a, b) => {
        const firstVal = typeof a[column] === 'boolean' ? String(a[column]) : a[column] as string | number;
        const secondVal = typeof b[column] === 'boolean' ? String(b[column]) : b[column] as string | number;
        const res = this.compare(firstVal, secondVal);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  matches(patient: IPatient, term: string, pipe: PipeTransform = this.pipe) {
    return pipe.transform(patient.firstName?.toString()).includes(term.toLowerCase())
      || pipe.transform(patient.id?.toString()).includes(term.toLowerCase())
      || pipe.transform(patient.email?.toString()).includes(term.toLowerCase());
  }

  sendPatient(patient: IPatient) {
    this.subject.next(patient);
  }

  clearPatient() {
    this.subject.next(null);
  }

  getLocalPatientsFallsDiaryData(): {id: number, value: number}[] {
    const values: string | null = localStorage.getItem(this.FALLS_DIARY_KEY);
    if (values) {
      return JSON.parse(values);
    }
    return [];
  }

  getLocalPatientFallsDiaryData(id: number): number {
    const data: {id: number, value: number}[] = this.getLocalPatientsFallsDiaryData();
    const index = data?.findIndex((value: {id: number, value: number}) => value.id === id);
    return index !== -1 ? data[index].value : 0;
  }

  updateLocalPatientFallsDiaryData(id: number, value: number): boolean {
    const data: {id: number, value: number}[] = this.getLocalPatientsFallsDiaryData();
    const index = data?.findIndex((value: {id: number, value: number}) => value.id === id);
    if (data[index].value !== value) {
      data[index].value = value;
      localStorage.setItem(this.FALLS_DIARY_KEY, JSON.stringify(data));
      return true;
    }
    return false;
  }

  initLocalPatientsFallsDiaryData(ids: number[]) {
    const values = ids.map((value, index) => ({id: value, value: 0}));
    const data = this.getLocalPatientsFallsDiaryData();
    const differences = _.differenceBy(values, data, 'id');
    if (differences.length > 0) {
      localStorage.setItem(this.FALLS_DIARY_KEY, JSON.stringify([...data, ...differences]));
    }
  }

  clearFallsDiary() {
    localStorage.removeItem(this.FALLS_DIARY_KEY);
  }

  getPatient(): Observable<any> {
    return this.subject.asObservable();
  }
}
