import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SessionResponseDto} from "../../../services/http/model/sessionResponseDto";
import {PatientResponseDto} from "../../../services/http/model/patientResponseDto";
import {PatientService} from 'src/app/services/component/patient.service';
import {PatientService as HttpPatientService} from 'src/app/services/http/patient.service';
import {Subscription} from 'rxjs';import {ToastService} from "../../../services/component/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {LoggerService} from "../../../core/service/logger.service";
import {DateTime} from "luxon";

@Component({
  selector: 'app-recover-session-modal',
  templateUrl: './recover-session-modal.component.html',
  styleUrls: ['./recover-session-modal.component.scss']
})
export class RecoverSessionModalComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input() session: SessionResponseDto;
  @Input() patient: PatientResponseDto;

  model: NgbDateStruct;

  date: { year: number; month: number };
  selectedDate: any;
  minDate: any;
  maxDate: any;
  markDisabled: (date: NgbDate, current?: {year: number, month: number}) => boolean;

  loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private patientService: PatientService,
    private httpPatientService: HttpPatientService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private logger: LoggerService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.logger.debug('RecoverSessionModal', 'Session', this.session);
    const min: DateTime = DateTime.now(); // DateTime.now().startOf('week').plus({ week: 1 });
    const max: DateTime = DateTime.now().plus({ day: 4 }); // DateTime.now().startOf('week').plus({ week: 2, day: 2 });
    this.minDate = { day: min.day, month: min.month, year: min.year };
    this.maxDate = { day: max.day, month: max.month, year: max.year };
    // this.markDisabled = (date: NgbDate, current?: {year: number, month: number}) => {
    //   const dateTime: DateTime = DateTime.fromObject({day: date.day, month: date.month, year: date.year });
    //   return dateTime.weekday > 3;
    // }
  }

  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onDateSelect(event: NgbDate) {
    this.selectedDate = DateTime.fromObject({day: event.day, month: event.month, year: event.year}).toFormat('yyyy-MM-dd');
  }

  translate(word: string) {
    let words = word.split(" ");
    words[0] = this.translateService.instant(words[0]);
    return words.join(" ");
  }

  no() {
    this.activeModal.dismiss({ result: false });
  }

  yes(event) {
    event.preventDefault();
    try {
      this.loading = true;
      const subscription = this.httpPatientService.resetPatientWeek(this.patient.id, this.patient.activeWeek.weekNumber, {
        resetDate: this.selectedDate
      }).subscribe({
        next: () => {
          this.activeModal.dismiss({ result: true });
          this.patientService.reschedule(null);
        },
        error: (error: any) => {
          this.logger.debug('ExerciseConfirmModalComponent', 'Error', error);
          this.toastService.showDanger(!!error.message ? this.translateService.instant(error.message) : this.translateService.instant('DEFAULT'));
          this.activeModal.dismiss({ result: false });
        },
        complete: () => {
          this.loading = false;
          this.subscription.remove(subscription);
          this.logger.debug('RecoverSessionModalComponent', 'Session recovered');
        }
      });
      this.subscription.add(subscription);
    } catch (e) {
      this.activeModal.dismiss({ result: false });
      this.logger.warn('ExerciseConfirmModalComponent', 'Unable to start the session.', e);
    }
  }

}
