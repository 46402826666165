import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Breadcrumb } from '../../model/component/breadcrumb.model';
import { BreadcrumbService } from 'src/app/services/component/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Array<Breadcrumb>;
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {
    this.subscription = new Subscription();
    this.breadcrumbs = [];
  }

  ngOnInit() {
    this.breadcrumbs = this.breadcrumbService.breadcrumbs;
  }

  navigate(url: string): void {
    this.router.navigateByUrl(url).catch();
  }

}
