import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/service/authentication.service';
import { ILoggedInUser } from '../../dto/logged-in-user.interface';
import { ToastService } from '../../../services/component/toast.service';
import { UtilsService } from 'src/app/services/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  loggedInUser: ILoggedInUser | null;

  constructor(
    public util: UtilsService,
    private router: Router,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.authenticationService.user.subscribe({
      next: (user: ILoggedInUser | null) => this.loggedInUser = user,
      error: (err: Error) => this.showError(err)
    });
  }

  logout() {
    this.authenticationService.logout().subscribe({
      next: () => this.router.navigate(['']).catch((err: Error) => this.showError(err)),
      error: (err: Error) => this.showError(err)
    });
  }

  showError(err: Error): void {
    this.toastService.show(err?.message, { classname: 'bg-danger text-light', delay: 15000 })
  }
}
