import { Component, Input, OnInit } from '@angular/core';
import { PathologyCriteriaOptedEnum } from '../../enum/doctor/survey.enum';
import { UtilsService } from 'src/app/services/utils';

@Component({
  selector: 'app-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.scss']
})
export class SurveyItemComponent implements OnInit {
  @Input()
  item: any;
  @Input()
  i: number;
  @Input() dateOfBirth: string;
  isElder: boolean = false;
  isAgeSurveyDisabled: boolean = false;


  pathologyCriteriaOptedEnum = PathologyCriteriaOptedEnum;

  constructor(
    public util: UtilsService
  ) { }

  ngOnInit(): void {
    const first = this.item.description.slice(0, 3);
    if (first == "Age" || first == "Età") {
      let startAge = this.item.description.slice(4, 6);
      let lastAge = this.item.description.slice(-2);
      this.isAgeSurveyDisabled = true;
      const patientAge = this.util.getAgeFromDateOfBirth(this.dateOfBirth || '');
      this.isElder = patientAge >= +startAge && patientAge <= +lastAge ? true : false;
      this.item.opted = this.isElder ? this.pathologyCriteriaOptedEnum.YES : this.pathologyCriteriaOptedEnum.NO;
    }
  }

}
