<div class="profile">
    <div class="row">
        <div class="col-md-11 d-flex flex-column">
            <h1> {{'Profile' | translate}} </h1>
            <app-breadcrumb></app-breadcrumb>
        </div>
        <div class="col-md-1">
            <button type="button" *ngIf="view" class="btn btn-success" (click)="editProfile()">{{'Edit' |
                translate}}</button>
        </div>
    </div>

    <form [formGroup]="form" style="width: 70%">
        <div class="form-row">
            <div class="form-floating mb-3 me-3 col-md-5">
                <input class="form-control shadow-sm px-4 border-2" type="text" placeholder="Enter First Name"
                    id="firstName" required autocomplete="new-firstName" formControlName="firstName" *ngIf="!view"
                    [class.is-invalid]="formSubmitted && form.get('firstName')?.invalid">
                <label class="labelHead" for="firstName" *ngIf="!view">{{'FirstName' | translate}}</label>
                <small *ngIf="view">{{'FirstName' | translate}}</small>
                <div class="labelValue" *ngIf="view"> {{this.form.value.firstName}} </div>
                <div *ngIf="formSubmitted && form.get('firstName')?.invalid " class="alert">
                    <small class="text-danger" *ngIf="form.get('firstName')?.errors?.['required']">
                        {{'FirstNameRequired' | translate}}
                    </small>
                </div>
            </div>

            <div class="form-floating mb-3 me-3 col-md-5">
                <input class="form-control shadow-sm px-4 border-2" type="text" placeholder="Enter Last Name"
                    id="lastName" required autocomplete="new-lastName" formControlName="lastName" *ngIf="!view"
                    [class.is-invalid]="formSubmitted && form.get('lastName')?.invalid">
                <label class="labelHead" for="lastName" *ngIf="!view">{{'LastName' | translate}}</label>
                <small *ngIf="view">{{'LastName' | translate}}</small>
                <div class="labelValue" *ngIf="view"> {{this.form.value.lastName}} </div>
                <div *ngIf="formSubmitted && form.get('lastName')?.invalid " class="alert">
                    <small class="text-danger" *ngIf="form.get('lastName')?.errors?.['required']">
                        {{'LastNameRequired' | translate}}
                    </small>
                </div>
            </div>

            <div class="form-floating mb-3 me-3 col-md-5" *ngIf="theme.role==='DOCTOR'">
                <select id="specialization" class="form-control" formControlName="specialization" *ngIf="!view"
                    [class.is-invalid]="formSubmitted && form.get('specialization')?.invalid">
                    <option hidden [ngValue]="null">{{'SelectSpecialization' | translate}}</option>
                    <option *ngFor="let specialization of specializations | async" [value]="specialization.id">
                        {{specialization.name}}
                    </option>
                </select>
                <label class="labelHead" for="specialization" *ngIf="!view">{{'Specialization' | translate}}</label>
                <small *ngIf="view">{{'Specialization' | translate}}</small>
                <div class="labelValue" *ngIf="view"> {{this.form.value.specialization}} </div>
                <div *ngIf="formSubmitted && form.get('specialization')?.invalid " class="alert">
                    <small class="text-danger" *ngIf="form.get('specialization')?.errors?.['required']">
                        {{'SpecializationRequired!' | translate}}
                    </small>
                </div>
            </div>


            <div class="form-floating mb-3 me-3 col-md-5" *ngIf="theme.role==='PATIENT'">
                <input class="form-control shadow-sm px-4 border-2" type="number" placeholder="Enter Age" id="age"
                    required autocomplete="new-age" formControlName="age" *ngIf="!view">
                <label class="labelHead" for="age" *ngIf="!view">{{'Age' | translate}}</label>
            </div>

            <div class="form-floating mb-3 me-3 col-md-5">
                <input class="form-control shadow-sm px-4 border-2" type="email" placeholder="Enter Email Address"
                    id="email" required autocomplete="new-email" formControlName="email" *ngIf="!view">
                <label class="labelHead" for="email" *ngIf="!view">{{'Email Address' | translate}}</label>
                <small *ngIf="view">{{'EmailAddress' | translate}}</small>
                <div class="labelValue" *ngIf="view"> {{this.form.value.email}} </div>
            </div>

            <div class="form-group col-md-12 d-flex justify-content-between holder mt-4">
                <button *ngIf="!view" type="button" class="btn mb-2 btn-primary shadow-sm" (click)="save()">
                    {{'SaveChanges' | translate}}
                </button>
                <button *ngIf="view" type="button" class="btn btn-success changePassword"
                    (click)="changePassword(content)">
                    {{'ChangePassword' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="modal-title">{{'ChangePassword' | translate}}</h2>
    </div>
    <div class="modal-body">
        <form [formGroup]="formPassword">
            <div class="form-row">
                <div class="form-floating mb-3 me-3 col-md-5 w-100">
                    <input class="form-control shadow-sm px-4 border-2 " type="password"
                        placeholder="Enter Current Password" id="oldPassword" required autocomplete="new-oldPassword"
                        formControlName="oldPassword"
                        [class.is-invalid]="formSubmitted && formPassword.get('oldPassword')?.invalid">
                    <label for="oldPassword"> {{'CurrentPassword' | translate}}</label>
                    <div *ngIf="formSubmitted && formPassword.get('oldPassword')?.invalid " class="alert">
                        <small class="text-danger" *ngIf="formPassword.get('oldPassword')?.errors?.['required']">
                            {{'OldPasswordRequired' | translate}}
                        </small>
                    </div>
                </div>
                <div class="form-floating mb-3 me-3 col-md-5 w-100">
                    <input class="form-control shadow-sm px-4 border-2 " type="password"
                        placeholder="Enter New Password" id="newPassword" required autocomplete="new-newPassword"
                        formControlName="newPassword"
                        [class.is-invalid]="formSubmitted && formPassword.get('newPassword')?.invalid">
                    <label for="newPassword">{{'NewPassword' | translate}}</label>
                    <div *ngIf="formSubmitted && formPassword.get('newPassword')?.invalid " class="alert">
                        <small class="text-danger" *ngIf="formPassword.get('newPassword')?.errors?.['required']">
                            {{'NewPasswordRequired' | translate}}
                        </small>
                        <small class="text-danger" *ngIf="formPassword.get('newPassword')?.errors?.['minlength']">
                            {{'PassswordMinimumCharacters' | translate}}
                        </small>
                        <small class="text-danger" *ngIf="formPassword.get('newPassword')?.errors?.['pattern']">
                            {{'PasswordContainCharacters' | translate}}
                        </small>
                    </div>
                </div>
                <div class="form-floating mb-3 me-3 col-md-5 w-100">
                    <input class="form-control shadow-sm px-4 border-2 " type="password" placeholder="Confirm Password"
                        id="confirmPassword" required autocomplete="new-confirmPassword"
                        formControlName="confirmPassword"
                        [class.is-invalid]="formSubmitted && formPassword.get('confirmPassword')?.invalid">
                    <label for="confirmPassword"> {{'ConfirmPassword' | translate}}</label>
                    <div *ngIf="formSubmitted && formPassword.get('confirmPassword')?.invalid " class="alert">
                        <small class="text-danger" *ngIf="formPassword.get('confirmPassword')?.errors?.['required']">
                            {{'ConfirmPasswordRequired' | translate}}
                        </small>
                        <small class="text-danger" *ngIf="formPassword.get('confirmPassword')?.errors?.['minlength']">
                            {{'PassswordMinimumCharacters' | translate}}
                        </small>
                        <small class="text-danger" *ngIf="formPassword.get('confirmPassword')?.errors?.['pattern']">
                            {{'PasswordContainCharacters' | translate}}
                        </small>
                        <small class="text-danger"
                            *ngIf="formPassword.get('confirmPassword')?.errors?.['confirmPasswordValidator']">
                            {{'PassswordNotMatch' | translate}}
                        </small>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="d-grid gap-2 d-flex justify-content-between">
            <button class="btn btn-secondary m-4" type="button" (click)="modal.dismiss('Cross click')">
                {{'Cancel' | translate}}
            </button>
            <button class="btn btn-primary m-4 btn-success" type="button" (click)="savePassword()">
                {{'ChangePassword' | translate}}
            </button>
        </div>
    </div>
</ng-template>
