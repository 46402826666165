import { Component, Input } from '@angular/core';
import { EnState } from '../../enum/component.enum';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    @Input()
    message: string;

    @Input()
    inverted: boolean;

    @Input()
    neutral: boolean;

    @Input()
    fixed: boolean;

    enState = EnState;

    constructor() {
        this.inverted = false;
        this.neutral = false;
        this.fixed = false;
    }

}
