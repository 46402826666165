<div class="custom-modal">
  <div class="modal-header d-flex justify-content-between align-items-start">
    <h1>{{'ContinueSession' | translate}}</h1>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="modal-body">
      <p>{{'AreYouSureWantContinueSession' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="no()">{{'No' | translate}}</button>
    <button class="btn btn-primary" (click)="yes()">{{'YesStart' | translate}}</button>
  </div>
</div>
