<div class="container-fluid">
    <div class="row no-gutter">
        <div class="col-md-6">
            <img src="assets/innerpageLogo.svg" class="img-fluid p-5" alt="...">
            <div class="login">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 col-xl-5 mx-auto form">
                            <h3 class="display-4">{{'Login' | translate}}</h3>
                            <p class="text-muted mb-4">{{'LetUsContinue' | translate}}</p>
                            <form [formGroup]="form" class="needs-validation">
                                <div class="form-floating mb-3">
                                    <input class="form-control shadow-sm px-4 border-2" type="email"
                                        placeholder="{{'EmailAddress' | translate}}" id="floatingEmailInput" required
                                        autocomplete="new-username" formControlName="username"
                                        [class.is-invalid]="formSubmitted && form.controls['username'].invalid">
                                    <label for="floatingEmailInput">{{'EmailAddress' | translate}}</label>
                                    <div *ngIf="formSubmitted && form.controls['username'].invalid " class="alert">
                                        <small class="text-danger"
                                            *ngIf="form.controls['username'].errors?.['required']">
                                            {{'EmailAddressRequired' | translate}}
                                        </small>
                                        <small class="text-danger" *ngIf="form.controls['username'].errors?.['email']">
                                            {{'EmailAddressValid' | translate}}
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!form.controls['username'].errors?.['required'] && (form.controls['username'].errors?.['minlength'] || form.controls['username'].errors?.['maxlength'])">
                                            {{'EmailAddressMust' | translate}}
                                        </small>
                                    </div>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control shadow-sm px-4 border-2" type="password"
                                        placeholder="********" id="floatingPasswordInput" formControlName="password"
                                        required autocomplete="new-password"
                                        [class.is-invalid]="formSubmitted && form.controls['password'].invalid">
                                    <label for="floatingPasswordInput">{{'Password' | translate}}</label>
                                    <div *ngIf="formSubmitted && form.controls['password'].invalid " class="alert">
                                        <small class="text-danger"
                                            *ngIf="form.controls['password'].errors?.['required']">
                                            {{'PasswordRequired' | translate}}
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!form.controls['password'].errors?.['required'] && (form.controls['password'].errors?.['minlength'] || form.controls['password'].errors?.['maxlength'])">
                                            {{'PasswordMust' | translate}}
                                        </small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 d-flex justify-content-between">
                                        <button type="button" (click)='login($event)'
                                            class="btn login-btn mb-2">{{'Login' | translate}}</button>
                                        <button type="button" *ngIf="false"
                                            class="btn btn-link mb-2 nounderline forgot-password-btn">Forgot
                                            {{'ForgotPassword' | translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 d-none d-md-flex bg-image "></div>
    </div>
</div>
<ng-template #dangerTpl>
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z" />
    </svg>
    {{'WrongUsernamePassword' | translate}}
</ng-template>
