import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EnvironmentService } from 'src/app/core/service/environment.service';
import { IEvaluationSurveyType, IEvaluationSurvey, ISurveyAnswerDetail, IQuestion, IStaticRefrenceData } from 'src/app/shared/dto/data/evaluation-survey.interface';
import { QuestionTypeEnum } from 'src/app/shared/enum/evaluation-survey/evaluation-survey-type-enum';

@Injectable({ providedIn: 'root' })
export class EvaluationSurveyService {

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  getEvaluationSurveys(doctorId: number, patientId: number): Observable<IEvaluationSurveyType[]> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/evaluation-surveys/list', { doctorId, patientId });
    return this.http.get<IEvaluationSurveyType[]>(url);
  }

  getEvaluationSurveysByPatientId(doctorId: number, patientId: number): Observable<IEvaluationSurvey[]> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/evaluation-surveys', { doctorId, patientId });
    return this.http.get<IEvaluationSurvey[]>(url);
  }

  getEvaluationSurveysBySurveyId(doctorId: number, patientId: number, surveyId: number): Observable<IQuestion[]> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/evaluation-surveys/:surveyId', { doctorId, patientId, surveyId });
    return this.http.get<IQuestion[]>(url).pipe(map(this.transformQuestionaire));
  }

  transformQuestionaire(questionaire: IQuestion[]): IQuestion[] {
    const result: any[] = [];
    questionaire.forEach((ques: IQuestion) => {
      const quesCopy = JSON.parse(JSON.stringify({ ...ques }));
      delete quesCopy.questionAnswerId;

      if (ques.type === QuestionTypeEnum.SINGLE_CHOICE) {
        const existingQuesIdx = result.findIndex(q => ques.questionId === q.questionId);
        const answer = { ...ques.answer, questionAnswerId: ques.questionAnswerId };

        if (existingQuesIdx < 0) {
          const newQues = { ...quesCopy, options: [answer] } as IQuestion;
          result.push(newQues);
        } else {
          result[existingQuesIdx].options?.push(answer);
        }
      } else {
        result.push(ques);
      }
    });
    return result;
  }

  getSurveyAnswersByPatientEvaluationId(doctorId: number, patientId: number, patientEvaluationSurveysId: number): Observable<ISurveyAnswerDetail[]> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/evaluation-surveys/answers/:patientEvaluationSurveysId', { doctorId, patientId, patientEvaluationSurveysId });
    return this.http.get<ISurveyAnswerDetail[]>(url);
  }

  saveEvaluationSurveysBySurveyId(doctorId: number, patientId: number, surveyId: number, formData: any): Observable<any> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/evaluation-surveys/:surveyId', { doctorId, patientId, surveyId });
    return this.http.post<any>(url, formData);
  }

  saveEvaluationByPatientEvaluationSurveysId(doctorId: number, patientId: number, patientEvaluationSurveysId: number, formData: any): Observable<any> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/evaluation-surveys/:patientEvaluationSurveysId', { doctorId, patientId, patientEvaluationSurveysId });
    return this.http.put<any>(url, formData);
  }

  getAppointmentsTrialCount(doctorId: number, patientId: number): Observable<any> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/appointments/trial', { doctorId, patientId });
    return this.http.get<any>(url);
  }

  getStaticReferenceData(doctorId: number, formData: IStaticRefrenceData) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/static-reference-data', { doctorId });
    return this.http.post<any>(url, formData);
  }
}
