<div class="d-flex mt-5">
    <div  class="d-flex justify-content-between flex-column" style="max-height: 8rem; min-width: 6rem">
        <div class="card-body align-items-center d-flex justify-content-center falls-diary-date">
            <div class="text-center fs-3">{{fallsDiaryValue.eventDate | dateFilter: 'date'}}</div>
        </div>
        <div class="card-body align-items-center d-flex justify-content-center opacity-75 month falls-diary-month">
            <div class="text-center">{{fallsDiaryMonthValue}}</div>
            <div class="text-center">{{fallsDiaryValue.eventDate | dateFilter: 'year'}}</div>
        </div>
    </div>
    <div class="w-100">
        <div class="ms-3" *ngFor="let _label of labels; let idx = index">
            <div class="row mb-1" *ngIf="idx % 3 === 0">
                <div class="col-12 col-sm-6 col-md-4 grid-container mb-4" *ngFor="let _item of [1, 2, 3]; let lidx = index">
                    <img [src]="'assets/falls-diary/' + labels[lidx + idx].icon + '.svg'" class="grid-child">
                    <div class="d-flex justify-content-center grid-child flex-column">
                        <small> {{labels[lidx + idx].label | translate}} </small>
                        <div class="fw-bold"> {{fallsDiaryValue[labels[lidx + idx].value] | translate}} </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-11 grid-container ms-3 mb-4">
            <img src="assets/falls-diary/notes.svg" class="grid-child">
            <div class="d-flex justify-content-center grid-child flex-column">
                <small> {{'NotesByPatient'| translate}}</small>
                <div>{{fallsDiaryValue.notes}}</div>


            </div>
        </div>
    </div>
    <hr class=" border-2 border-top">
</div>
