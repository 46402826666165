import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from 'src/app/services/component/toast.service';
import {PatientService} from 'src/app/services/component/patient.service';
import {PatientService as HttpPatientService} from 'src/app/services/http/patient.service';
import {Subscription} from 'rxjs';
import {SessionResponseDto} from "../../../services/http/model/sessionResponseDto";
import {TranslateService} from "@ngx-translate/core";
import {LoggerService} from "../../../core/service/logger.service";
import {PatientResponseDto} from "../../../services/http/model/patientResponseDto";
import {DateTime} from "luxon";

@Component({
  selector: 'app-schedule-exercise-modal',
  templateUrl: './schedule-exercise-modal.component.html',
  styleUrls: ['./schedule-exercise-modal.component.scss']
})
export class ScheduleExerciseModalComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input() session: SessionResponseDto;
  @Input() patient: PatientResponseDto;

  model: NgbDateStruct;

  date: { year: number; month: number };
  selectedDate: any;
  minDate: any;
  maxDate: any;
  markDisabled: (date: NgbDate, current?: {year: number, month: number}) => boolean;

  loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private patientService: PatientService,
    private httpPatientService: HttpPatientService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private logger: LoggerService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.logger.debug('ScheduleExerciseModal', 'Session', this.session);
    const today = DateTime.now();
    const patientWeekStartDate: DateTime = DateTime.fromISO(this.patient.weekStartDate);
    const sessionWeekStartDate: DateTime = DateTime.fromISO(this.session.weekStartDate);
    const delay = Math.round(sessionWeekStartDate.diffNow('day').days);
    console.log('Session', sessionWeekStartDate, delay);

    const min: DateTime = delay < 0 ? today : sessionWeekStartDate.plus({ day: 1 }); // DateTime.fromJSDate(new Date(this.patient.weekStartDate)).plus({ week: 1 });
    const max: DateTime = today.plus({ week: 2 }); // DateTime.now().plus({ week: 2, day: 2 });
    this.minDate = { day: min.day, month: min.month, year: min.year };
    this.maxDate = { day: max.day, month: max.month, year: max.year };
    // this.markDisabled = (date: NgbDate, current?: {year: number, month: number}) => {
    //   const dateTime: DateTime = DateTime.fromObject({day: date.day, month: date.month, year: date.year });
    //   return dateTime.weekday > 3;
    // }
  }

  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onDateSelect(event: NgbDate) {
    this.selectedDate = DateTime.fromObject({day: event.day, month: event.month, year: event.year}).toFormat('yyyy-MM-dd');
  }

  translate(word: string) {
    let words = word.split(" ");
    words[0] = this.translateService.instant(words[0]);
    return words.join(" ");
  }

  no() {
    this.activeModal.dismiss({ result: false });
  }

  yes(event) {
    event.preventDefault();
    try {
      this.loading = true;
      const subscription = this.httpPatientService.reschedulePatientSession(this.patient.id, this.selectedDate, true, {
        sessionId: 0,
        oldWeekNumber: 0,
      }).subscribe({
        next: (response) => {
          if (response) {
            this.activeModal.dismiss({ result: true });
          }
        },
        error: (error: any) => {
          this.logger.debug('ExerciseConfirmModalComponent', 'Error', error);
          this.toastService.showDanger(
            error.message === 'INVALID_SESSION_RESCHEDULING' ? this.translateService.instant(error.message) : this.translateService.instant('DEFAULT')
          )
          this.activeModal.dismiss({ result: false });
        },
        complete: () => {
          this.loading = false;
          this.subscription.remove(subscription);
          this.logger.debug('ScheduleExerciseModalComponent', 'Session scheduled');
        }
      });
      this.subscription.add(subscription);
    } catch (e) {
      this.activeModal.dismiss({ result: false });
      this.logger.warn('ExerciseConfirmModalComponent', 'Unable to start the session.', e);
    }
  }

}
