import { Injectable } from '@angular/core';
import { routes } from '../../app-routing.module';
import { UserRoleEnum } from '../../shared/enum/user-role.enum';

@Injectable()
export class ThemeService {

  public get buildType(): string {
    return (routes?.find((route) => route?.path?.includes(UserRoleEnum.PATIENT.toLowerCase())) ? UserRoleEnum.PATIENT : UserRoleEnum.DOCTOR).toLowerCase();
  }

  public get current(): string {
    return localStorage.getItem('theme') ?? this.buildType?.toLowerCase();
  }

  public set current(value: string) {
    localStorage.setItem('theme', value);
    this.style.href = `${value}.css`;
    // this.style.href = `/src/stylesheets/themes/${value}/variables.scss`;
  }

  public get role(): string {
    return localStorage.getItem('role') ?? this.buildType?.toUpperCase();
  }

  public set role(value: string) {
    localStorage.setItem('role', value);
  }

  private readonly style: HTMLLinkElement;

  constructor() {
    this.style = document.createElement('link');
    this.style.rel = 'stylesheet';
    document.head.appendChild(this.style);

    if (localStorage.getItem('theme') !== undefined) {
      this.style.href = `${this.current}.css`;
      // this.style.href = `/src/stylesheets/themes/${this.current}/variables.scss`;
    }
  }
}
