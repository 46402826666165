import { NgModule } from '@angular/core';
import { CommonModule, LowerCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './component/login/login.component';
import { EnvironmentService } from './service/environment.service';
import { AuthenticationService } from './service/authentication.service';
import { LoggerService } from './service/logger.service';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import {ThemeService} from "./service/theme.service";

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    TranslateModule
  ],
  providers: [
    AuthenticationService,
    EnvironmentService,
    LoggerService,
    ThemeService,
    LowerCasePipe
  ]
})
export class CoreModule { }
