import { BreadcrumbService } from './breadcrumb.service';
import { VuplexService } from './vuplex.service';
import {ToastService} from "./toast.service";

/** Service Component providers */
export const COMPONENT_SERVICE_PROVIDERS = [
  BreadcrumbService,
  VuplexService,
  ToastService
];
