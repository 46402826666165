import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggerService {
    private readonly production = environment.production;
    private reference: string | null = null;
    private value: string | null = null;

    info(message: any) {
        this.value = message;
        const args = ['%cINFO: ' + '%c' + message, 'color:#3D88FE', 'font-weight:800'];
        console.log.apply(console.log, args);
    }

    debug(message: any, ...params: Array<any>) {
        this.value = message;
        let args = ['%cDEBUG: ' + '%c' + message, 'color:#1db100', 'font-weight:800'];
        args = args.concat(params);
        if (!this.production) {
            console.log.apply(console.log, args);
        }
    }

    warn(message: any, ...params: Array<any>) {
        this.value = message;
        let args = ['%cWARN: ' + '%c' + message, 'color:#ffc107', 'font-weight:800'];
        args = args.concat(params);
        if (!this.production) {
            console.warn.apply(console.log, args);
        }
    }

    error(message: any) {
        this.value = message;
        const args = ['%cERROR: ' + '%c' + message, 'color:#ee220c', 'font-weight:800'];
        if (!this.production) {
            console.error.apply(console.log, args);
        }
    }

    set klass(value: string | null) {
        this.reference = value || null;
    }

    get klass(): string | null {
        return this.reference;
    }

    get message(): string | null {
        return this.value;
    }
}
