import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../../core/service/authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Get the auth token from the service.
        const token = this.authenticationService.token;
        // send cloned request with header to the next handler.
        return !!token && !request.url.includes('authenticate') ? next.handle(request.clone({
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
            // headers: request.headers.set('Authorization', token)
        })) : next.handle(request);

    }
}
