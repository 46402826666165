<nav class="navbar align-items-center px-5">
    <a class="navbar-brand" routerLink="dashboard">
      <img src="assets/innerpageLogo.svg" alt="Medico Logo">
    </a>
  <ul class="mr-4 p-0 " ngbDropdown>
    <a aria-expanded="false" class="nav-link me-4 d-flex align-items-center"
       ngbDropdownToggle role="button">
            <img *ngIf="false" src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img(31).webp" class="rounded-square"
              height="40" alt="Portrait of a Woman" loading="lazy" />
            <div class="d-flex m-2 flex-column navDetail">
              <small class="text-muted"> {{'Welcome' | translate}} </small>
              <p class="m-0"> {{ util.fullName(loggedInUser) }} </p>
            </div>
          </a>
    <ul style="left: unset" class="end-0 " aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>

            <li>
              <a ngbDropdownItem routerLink="profile">{{'MyProfile' | translate}}</a>
            </li>
            <li *ngIf="false">
              <a ngbDropdownItem routerLink="profile">{{'Settings' | translate}}</a>
            </li>
            <li>
              <a ngbDropdownItem (click)='logout()'>{{'Logout' | translate}}</a>
            </li>
          </ul>
      </ul>
  </nav>
