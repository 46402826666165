export class Base {
    id: number = 0;

    toString(): string {
        return JSON.stringify(this, Object.keys(this.constructor.prototype));
    }

    stringify(): string {
        return JSON.stringify(this);
    }

    equals(model: Base): boolean {
        if (!model) { return false; }
        return this === model;
    }

    isFunction(fn: Function) {
        return fn && {}.toString.call(fn) === '[object Function]';
    }
}
