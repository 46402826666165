/* "Barrel" of Http Interceptors */
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {HttpAuthInterceptor} from './http-auth.interceptor';
import {HttpErrorInterceptor} from './http-error.interceptor';
import {HttpXhrInterceptor} from './http-xhr.interceptor';

/** Http interceptor providers in outside-in order */
export const INTERCEPTOR_PROVIDERS = [
    {provide: HTTP_INTERCEPTORS, useClass: HttpXhrInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true}
];
