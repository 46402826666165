import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {Breadcrumb} from '../../shared/model/component/breadcrumb.model';

@Injectable()
export class BreadcrumbService {
    private subject: BehaviorSubject<Breadcrumb>;
    private readonly values: Array<Breadcrumb>;

    constructor(
        private router: Router
    ) {
        this.values = [];
        this.subject = new BehaviorSubject<any>('');
    }

    valueChanges(): Observable<Breadcrumb> {
        return this.subject.asObservable();
    }

    /**
     * Adds new breadcrumb into the breadcrumbs menu.
     * @param breadcrumb: the new breadcrumb.
     */
    add(breadcrumb: Breadcrumb) {
        const index: number = this.values.findIndex(value => value.title === breadcrumb.title);
        if (index === -1) {
            this.values.push(breadcrumb);
            this.subject.next(breadcrumb);
        }
    }

    /**
     * Removes breadcrumb from the breadcrumbs menu.
     * @param breadcrumb: the old breadcrumb.
     */
    remove(breadcrumb: Breadcrumb) {
        const index: number = this.values.findIndex(value => value.title === breadcrumb.title);
        if (index !== -1) {
            this.values.splice(index, 1);
            const last: Breadcrumb = this.values[this.values.length - 1];
            this.subject.next(last);
        }
    }

    get breadcrumbs(): Array<Breadcrumb> {
        return this.values;
    }
}
