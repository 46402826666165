<div class="col-md">
  <small class="py-3"> {{inclusionDetail.pathologyCriteria.description}} </small>
  <h6 [ngClass]="inclusionDetail.opted ? 'yes' : 'no'" class="py-2 m-0"> {{(inclusionDetail.opted ?
    'Yes' :
    'No') | translate}}</h6>
</div>
<!-- <div class="col-md-6">
      <div class="content ps-4 pt-4">
          <small> {{inclusionDetails[index + 1].pathologyCriteria.description}} </small>
          <h6 class="pt-2" [ngClass]="inclusionDetails[index + 1].opted ? 'yes' : 'no'">
              {{inclusionDetails[index
              + 1].opted ? 'Yes' : 'No'}}</h6>
      </div>
  </div> -->

