import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {INTERCEPTOR_PROVIDERS} from './interceptor';
import {COMPONENT_SERVICE_PROVIDERS} from './component';
import {HTTP_SERVICE_PROVIDERS} from './http';
import {UtilsService} from './utils';


@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        COMPONENT_SERVICE_PROVIDERS,
        HTTP_SERVICE_PROVIDERS,
        INTERCEPTOR_PROVIDERS,
        UtilsService
    ]
})
export class ServicesModule {
}
