import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {asyncScheduler, Observable, Subscription} from 'rxjs';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {AuthenticationService} from 'src/app/core/service/authentication.service';
import {EvaluationSurveyService} from 'src/app/services/http/evaluation-survey.service';
import {
  IMetadata,
  IPostParamsBypatientEvaluationSurveysId,
  IPostParamsBySurveysId,
  IQuestion,
  IStaticRefrenceChart,
  IStaticRefrenceData,
  ISurvey,
  ISurveyAnswerDetail
} from '../../dto/data/evaluation-survey.interface';
import {ILoggedInUser} from '../../dto/logged-in-user.interface';
import {
  EvalTitleEnum,
  EvaluationSurveyTypeEnum,
  QuestionTypeEnum,
  StaticReferenceKey
} from '../../enum/evaluation-survey/evaluation-survey-type-enum';
import {ToastService} from '../../../services/component/toast.service';

@Component({
  selector: 'app-evaluation-modal',
  templateUrl: './evaluation-modal.component.html',
  styleUrls: ['./evaluation-modal.component.scss']
})
export class EvaluationModalComponent implements OnInit, OnDestroy {
  @Input() survey: ISurvey;
  @Input() evalType: string;
  @Input() evalTitle: string;
  @Input() trialCount: number;

  subscription: Subscription;

  form: UntypedFormGroup;
  doctorId: number;
  loggedInUser: ILoggedInUser | null;
  questions: IQuestion[] = [];
  answers: ISurveyAnswerDetail[];
  transformedMetadata: IMetadata;
  questionTypeEnum = QuestionTypeEnum;
  evalTitleEnum = EvalTitleEnum;
  evalTypeEnum = EvaluationSurveyTypeEnum;
  editable: boolean = false;
  staticRefrenceChart: IStaticRefrenceChart[] = [];
  checkFinalValue: string;
  isEvalFluenza: boolean = false;
  timeUpAndGoValue: number;
  tugDualTaskValue: number;
  selectionTypeAnswer: any;
  questionIdHnY: string;

  constructor(
    public activeModal: NgbActiveModal,
    private evaluationSurveyService: EvaluationSurveyService,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) {
    this.form = new UntypedFormGroup({});
    this.authenticationService.user.subscribe({
      next: (user: ILoggedInUser | null) => {
        this.loggedInUser = user;
        this.doctorId = this.loggedInUser?.id as number;
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    });
    this.subscription = new Subscription();
  }


  get isAlreadyAnswered(): boolean {
    return !!this.survey?.patientEvaluationSurveysId;
  }

  get hasStaticReferenceData(): boolean {
    return !!this.staticRefrenceChart;
  }

  get valueISNotNaN(): boolean {
    return !isNaN(this.survey?.value);
  }

  ngOnInit(): void {
    /* DO NOT CHANGE THE ORDER OF THESE STATEMENTS */
    this.transformeMetadata();
    this.editable = this.evalTitle === 'MINI_BEST' ? true : !this.isAlreadyAnswered || (!this.transformedMetadata || !this.hasStaticReferenceData);
    this.initQuestionaireData();
  }

  ngOnDestroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  transformeMetadata(): void {
    if (this.survey?.evaluationFinalValueMetadata) {
      this.transformedMetadata = {};
      if (this.evalType === EvaluationSurveyTypeEnum.FLUENZA_VERBALE_FONEMICA || this.evalType === EvaluationSurveyTypeEnum.FLUENZA_VERBALE_SEMANTICA) {
        this.isEvalFluenza = true;
        this.getStaticRefrenceChart(this.evalType);
      } else {
        for (const key in this.survey.evaluationFinalValueMetadata) {
          this.transformedMetadata[key] = this.survey.evaluationFinalValueMetadata[key];
        }
      }
    }
  }

  initQuestionaireData(): void {
    const {patientId, surveyId, patientEvaluationSurveysId} = this.survey;
    const observables = [] as Observable<IQuestion[] | ISurveyAnswerDetail[]>[];
    observables.push(this.evaluationSurveyService.getEvaluationSurveysBySurveyId(this.doctorId, patientId, surveyId));
    if (patientEvaluationSurveysId) {
      observables.push(this.evaluationSurveyService.getSurveyAnswersByPatientEvaluationId(this.doctorId, patientId, patientEvaluationSurveysId));
    }
    forkJoin(observables).subscribe({
      next: ([questions, answers]) => {
        this.questions = (questions || []) as IQuestion[];
        this.answers = (answers || []) as ISurveyAnswerDetail[];

        this.questions?.forEach((question: IQuestion) => {
          const answer = this.getAnswer(question.questionId);

          const ctrlOpts = {
            value: question.type === this.questionTypeEnum.SINGLE_CHOICE ? (answer?.questionAnswerId || '') : (answer?.evaluationAnswerValue || ''),
            disabled: !this.editable
          };
          if (question.title === 'TIMED UP & GO') {
            this.timeUpAndGoValue = +ctrlOpts.value;
          }
          if (question.title === 'TUG DUAL TASK') {
            this.tugDualTaskValue = +ctrlOpts.value;
          }
          this.form.addControl(String(question.questionId), new UntypedFormControl(ctrlOpts, [Validators.required]));
        });
        if (this.evalType === this.evalTypeEnum.H_and_Y) {
          this.selectionTypeAnswer = this.questions[0].options || [];
          this.questionIdHnY = String(this.questions[0].questionId);
        }
      },
      error: (err: Error) => this.toastService.showDanger(err.message),
      complete: () => this.applyEvaluationFormLogic()
    })
  }

  applyEvaluationFormLogic(): void {
    if (this.evalType === EvaluationSurveyTypeEnum.NFOG) {
      const c154 = this.form?.controls['154'];
      const c156 = this.form?.controls['156'];
      const c158 = this.form?.controls['158'];
      // initial setup
      if (c154?.value === 507) {
        Object.keys(this.form.controls).forEach((key) => {
          if (key !== '154') {
            this.form.controls[key].disable({
              onlySelf: true,
              emitEvent: false
            });
          }
        });
      }
      if (c156?.value === 512 && this.form.controls['157'].enabled) {
        this.form.controls['157'].reset({onlySelf: true, emitEvent: false});
        this.form.controls['157'].disable({onlySelf: true, emitEvent: false});
      }
      if (c158?.value === 521 && this.form.controls['159'].enabled) {
        this.form.controls['159'].reset({onlySelf: true, emitEvent: false});
        this.form.controls['159'].disable({onlySelf: true, emitEvent: false});
      }
      // observe the changes
      this.subscription.add(c154?.valueChanges.subscribe((value) => {
        if (value === '507') {
          Object.keys(this.form.controls).forEach((key) => {
            if (key !== '154') {
              this.form.controls[key].disable({
                onlySelf: true,
                emitEvent: false
              });
            }
          });
        } else {
          this.form.enable({onlySelf: true, emitEvent: false});
        }
      }));
      this.subscription.add(c156?.valueChanges.subscribe((value) => {
        if (value === '512' && this.form.controls['157'].enabled) {
          this.form.controls['157'].reset({onlySelf: true, emitEvent: false});
          this.form.controls['157'].disable({onlySelf: true, emitEvent: false});
        } else {
          this.form.controls['157'].enable({onlySelf: true, emitEvent: false});
        }
      }));
      this.subscription.add(c158?.valueChanges.subscribe((value) => {
        if (value === '521' && this.form.controls['159'].enabled) {
          this.form.controls['159'].reset({onlySelf: true, emitEvent: false});
          this.form.controls['159'].disable({onlySelf: true, emitEvent: false});
        } else {
          this.form.controls['159'].enable({onlySelf: true, emitEvent: false});
        }
      }));
    }
    if (this.evalType === EvaluationSurveyTypeEnum.PD_CRS) {
      let fieldValidation = [
        {max: 10, arrays: [165, 166, 167, 168]},
        {max: 20, arrays: [164, 170]},
        {max: 12, arrays: [163, 169]},
        {max: 30, arrays: [171]}
      ];
      let questionIds = [163, 164, 165, 166, 167, 168, 169, 170, 171];
      this.subscription.add(this.form.valueChanges.subscribe((value) => {
        questionIds.forEach((questionId) => {
          fieldValidation.forEach((field) => {
            if (field.arrays.includes(questionId) && value[questionId] > field.max) {
              this.toastService.showDanger(`il valore non deve superare ${field.max}`);
              this.form.controls[questionId].reset();
            }
          });
        });
      }));
    }

  }

  makeEditable(): void {
    this.editable = true;
    this.form.enable();
  }

  makeNonEditable(): void {
    this.editable = false;
    this.form.disable();
  }

  /* Submit the answers */
  submit() {
    const formValue = this.form.value;
    /* Update */
    if (this.survey?.patientEvaluationSurveysId) {
      if (this.evalType === EvaluationSurveyTypeEnum.NFOG) {
        this.removeDisabledControls(this.form);
        let questionAnswers = Object.keys(this.form.controls).map(questionId => {
          const answer = this.getAnswer(Number(questionId));
          return {
            questionAnswerId: Number(formValue[questionId]) ?? '',
            patientEvaluationSurveyDetailId: answer?.patientEvaluationSurveyDetailId || 0
          };
        });
        const postParams: IPostParamsBypatientEvaluationSurveysId = {questionAnswers};
        return this.saveEvaluationByPatientEvaluationSurveysId(postParams);
      }
      const questionAnswers = Object.keys(formValue).map(questionId => {
        const answer = this.getAnswer(Number(questionId));
        return {
          questionAnswerId: answer?.questionType === this.questionTypeEnum.SINGLE_CHOICE ? Number(formValue[questionId]) : Number(answer?.questionAnswerId),
          answerValue: answer?.questionType === this.questionTypeEnum.SINGLE_CHOICE ? '' : String(formValue[questionId] || ''),
          patientEvaluationSurveyDetailId: answer?.patientEvaluationSurveyDetailId || 0
        };
      });
      const postParams: IPostParamsBypatientEvaluationSurveysId = {questionAnswers};
      this.saveEvaluationByPatientEvaluationSurveysId(postParams);
    } else {
      /* Create/New */
      let questionAnswers: Array<{
        questionAnswerId: number;
        answerValue?: string
      }> = Object.keys(formValue).map(questionId => {
        const question = this.getQuestion(Number(questionId));
        return {
          questionAnswerId: question.type === this.questionTypeEnum.SINGLE_CHOICE ? Number(formValue[questionId]) : Number(question?.questionAnswerId),
          answerValue: String(formValue[questionId] || '')
        };
      })
      if (this.evalType === EvaluationSurveyTypeEnum.NFOG) {
        this.removeDisabledControls(this.form);
        questionAnswers = Object.keys(this.form.controls).map(questionId => {
          const question = this.getQuestion(Number(questionId));
          return {
            questionAnswerId: question.type === this.questionTypeEnum.SINGLE_CHOICE ? Number(formValue[questionId]) : Number(question?.questionAnswerId),
          };
        });
      }
      const postParams: IPostParamsBySurveysId = {questionAnswers, trialCount: this.trialCount};
      this.saveEvaluationSurveysBySurveyId(postParams);
    }
  }

  removeDisabledControls(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(key => {
      if (form.controls[key].disabled) {
        form.removeControl(key);
      }
    });
  }


  getAnswer(questionId: number): ISurveyAnswerDetail {
    return this.answers?.find(((ans: ISurveyAnswerDetail) => ans.questionId === questionId)) as ISurveyAnswerDetail;
  }

  getQuestion(questionId: number): IQuestion {
    return this.questions?.find(((ques: IQuestion) => ques.questionId === questionId)) as IQuestion;
  }

  getDropdownValue(questionId: number): string {
    return String(this.questions.find(ques => ques.questionId === questionId)?.answer?.value || '');
  }

  updateQuestionAnswerId(event: Event, question): void {
    const questionAnswerId = Number((event.target as HTMLInputElement)?.value);
    const quesIndex = this.questions.findIndex(ques => ques.questionId === question.questionId);
    this.questions[quesIndex].questionAnswerId = questionAnswerId;
  }

  saveEvaluationByPatientEvaluationSurveysId(postParams: any) {
    this.evaluationSurveyService.saveEvaluationByPatientEvaluationSurveysId(this.doctorId, this.survey.patientId, this.survey.patientEvaluationSurveysId, postParams).subscribe({
      next: (response) => {
        if (response) {
          this.toastService.showSuccess('SavedSuccessfully');
          this.dismissModal({refetchEvaluationSurvey: true, patchData: response});
        } else {
          this.toastService.showDanger('SomethingWentWrong');
        }
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    })
  }

  saveEvaluationSurveysBySurveyId(postParams: any) {
    this.evaluationSurveyService.saveEvaluationSurveysBySurveyId(this.doctorId, this.survey.patientId, this.survey.surveyId, postParams).subscribe({
      next: (response) => {
        if (response) {
          this.toastService.showSuccess('SavedSuccessfully');
          this.dismissModal({patchData: response});
        } else {
          this.toastService.showDanger('SomethingWentWrong');
        }
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    })
  }

  dismissModal(data: any): void {
    asyncScheduler.schedule(() => {
      this.modalService.dismissAll(data);
    })
  }

  getStaticRefrenceChart(evalType: string) {
    let key;
    if (evalType === EvaluationSurveyTypeEnum.FLUENZA_VERBALE_FONEMICA) {
      key = StaticReferenceKey.FLUENZ_VERBALE_FINAL_VALUES_FONEMICA;
    } else if (evalType === EvaluationSurveyTypeEnum.FLUENZA_VERBALE_SEMANTICA) {
      key = StaticReferenceKey.FLUENZ_VERBALE_FINAL_VALUES_SEMANTICA;
    }
    let formData: IStaticRefrenceData = {
      dataKey: key
    }
    this.evaluationSurveyService.getStaticReferenceData(this.doctorId, formData).subscribe({
      next: (res) => {
        const startCols = ['Reference values', 'Outer limits', 'Inner limits'];
        const finalCols = [...startCols, ...Object.keys(res).filter(f => !startCols.includes(f))];

        finalCols.forEach(key => {
          this.checkFinalValue = this.survey.metadata.substring(7, this.survey.metadata.length - 1);
          this.staticRefrenceChart.push({key, value: res[key]});
        })

      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    })
  }

  changeAnswerValue(question, event) {
    const answerValue = Number((event.target as HTMLInputElement)?.value);
    if (question?.title === 'TIMED UP & GO') {
      this.timeUpAndGoValue = +answerValue;
    }
    if (question?.title === 'TUG DUAL TASK') {
      this.tugDualTaskValue = +answerValue;
    }
  }

  getRadioValue(min, max, evalValue) {
    let value: any = []
    if (min >= 0 && max >= 0) {
      for (let i = min; i <= max;) {
        value.push(i.toString())
        if (evalValue === this.evalTypeEnum.ABC) {
          i += 10
        } else {
          i += 1
        }
      }
    }

    return value
  }
}
