import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-continue-delay-session-modal',
  templateUrl: './continue-delay-session-modal.component.html',
  styleUrls: ['./continue-delay-session-modal.component.scss']
})
export class ContinueDelaySessionModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  no() {
    this.activeModal.dismiss({ result: false });
  }

  yes() {
    this.activeModal.dismiss({ result: true });
  }

}
