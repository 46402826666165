import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { DoctorService } from 'src/app/services/http/doctor.service';
import { ToastService } from '../../../services/component/toast.service';
import { ILoggedInUser } from '../../dto/logged-in-user.interface';
import { IInstrumentalData } from '../../dto/data/instrumental-data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-instrumental-data-selection-modal',
  templateUrl: './instrumental-data-selection-modal.component.html',
  styleUrls: ['./instrumental-data-selection-modal.component.scss']
})
export class InstrumentalDataSelectionModalComponent implements OnInit {

  @Input() public data;
  @Input() public type;
  @Input() public patientId;
  loggedInUser: ILoggedInUser | null;
  doctorId: number;
  isSelected: number;
  form = new UntypedFormGroup({
    instrumentalDataValue: new UntypedFormControl('')
  });

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    public activeModal: NgbActiveModal,
    private doctorService: DoctorService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user.subscribe({
      next: (user: ILoggedInUser | null) => {
        this.loggedInUser = user;
        this.doctorId = this.loggedInUser?.id as number;
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    });
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.isSelected = this.data[this.type] == true ? 1 : this.data[this.type] == false ? 2 : 3;
  }

  submit() {
    let data = {
      "nTime": this.data.nTime,
      "value": {
        [this.type]: this.isSelected == 1 ? "true" : this.isSelected == 2 ? "false" : ""
      }
    };
    this.doctorService.saveInstrumentalData(this.doctorId, this.patientId, data).subscribe({
      next: (res: IInstrumentalData) => {
        this.toastService.showSuccess('SavedSuccessfully');
        this.modalService.dismissAll({ response: true });
      },
      error: (err: Error) => this.toastService.showDanger(err.message)
    })
  }

}
