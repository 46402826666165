import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  toasts: any[] = [];

  constructor(private translateService: TranslateService) {}

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  showStandard(message: TemplateRef<any> | string) {
    message = typeof message === 'string' ? this.translateService.instant(message): message;
    this.show(message, { delay: 5000 });
  }

  showSuccess(message: TemplateRef<any> | string) {
    message = typeof message === 'string' ? this.translateService.instant(message): message;
    this.show(message, { classname: 'bg-success text-light', delay: 5000 });
  }

  showDanger(message: TemplateRef<any> | string) {
    message = typeof message === 'string' ? this.translateService.instant(message): message;
    this.show(message, { classname: 'bg-danger text-light', delay: 5000 });
  }
}
