import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {AuthenticationService} from 'src/app/core/service/authentication.service';
import {VuplexService} from 'src/app/services/component/vuplex.service';
import {PatientService} from 'src/app/services/component/patient.service';
import {PatientService as HttpPatientService} from 'src/app/services/http/patient.service';
import {ToastService} from '../../../services/component/toast.service';
import {UtilsService} from "../../../services/utils";
import {LoggerService} from "../../../core/service/logger.service";
import {EnvironmentService} from "../../../core/service/environment.service";
import {DateTime} from "luxon";
import {SessionResponseDto} from "../../../services/http/model/sessionResponseDto";
import {PatientResponseDto} from "../../../services/http/model/patientResponseDto";
import {TranslateService} from "@ngx-translate/core";
import {PatientExerciseStatusEn} from "../../enum/patient-exercise-status.enum";

@Component({
  selector: 'app-exercise-confirm-modal',
  templateUrl: './exercise-confirm-modal.component.html',
  styleUrls: ['./exercise-confirm-modal.component.scss'],
})
export class ExerciseConfirmModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() session: SessionResponseDto;
  @Input() patient: PatientResponseDto;

  today: DateTime = DateTime.now();

  schedule: boolean

  platform: string;
  json: any
  totalLap: number;

  loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private patientService: PatientService,
    private httpPatientService: HttpPatientService,
    private vuplexService: VuplexService,
    private authService: AuthenticationService,
    private utilsService: UtilsService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private logger: LoggerService,
  ) {
  }

  ngOnInit(): void {
    this.schedule = !this.today.hasSame(DateTime.fromISO(this.session.toStartDate), 'day')
  }

  ngOnDestroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  translate(word: string) {
    let words = word.split(" ");
    words[0] = this.translateService.instant(words[0]);
    return words.join(" ");
  }

  duration(totalTime: number) {
    return `${Math.round(totalTime / 60)} Min`
  }

  hasWeekSessionDelay(session: SessionResponseDto) {
    return Math.sign(Math.round(DateTime.fromISO(session.toStartDate).endOf('day').diffNow('day').days)) * Math.round(DateTime.fromISO(session.toStartDate).endOf('day').diffNow('day').days);
  }

  no() {
    this.activeModal.dismiss({ result: false });
  }

  yes(event) {
    event.preventDefault();
    try {
      if (this.vuplexService.isReady()) {
        this.loading = true;
        const OS = this.utilsService.getOS();
        if (OS?.includes('Mac OS')) {
          this.platform = 'ios';
        }
        if (OS?.includes('Linux')) {
          this.platform = 'linux';
        }
        if (OS?.includes('Windows')) {
          this.platform = 'windows';
        }
        const todayDateStr = new Date().toISOString().split('T')[0];
        const data = {
          startSessionDate: todayDateStr,
          startSequence: this.session.sequence,
          startWeek: this.session.week.weekNumber,
        };
        const subscription = this.httpPatientService
          .patchPatientSessionStart(this.patient.id, this.session.id, todayDateStr, data, this.platform)
          .subscribe({
            next: (res: any) => {
              this.logger.debug('ExerciseConfirmModal', `Session ${res}`);
              this.activeModal.dismiss({ result: true });
              this.totalLap = res.result[0].session.laps;
              const data = {
                host: this.environmentService.getHost(),
                jwt: this.authService.getAuthToken(),
                laps: res.result[0].session.laps,
                lapBreakSeconds: res.result[0].session.lapBreakSeconds,
                lastExerciseId: this.session.lastCompletedExerciseId,
                lastLap: this.session.pendingLap ? this.session.pendingLap - 1 : 0, // pendingLap mean what is the next lap pending
                pathology: res.result[0].session.pathology,
                healthyLimb: this.patient.healthyLimb,
                patientId: this.patient.id,
                sessionId: this.session.id,
                exercises: this.getTransformSessionData(res),
              };
              this.logger.info('Post message to Unity done.');
              this.logger.debug('Post message to Unity data:', JSON.stringify(data));
              this.vuplexService.postMessage({type: 'start', message: data});
            },
            error: (error: any) => {
              this.logger.debug('ExerciseConfirmModalComponent', 'Error', error);
              if (error.message == 'RESET_WEEK_REQUIRED') {
                this.toastService.showDanger('ResetWeekRequired');
                this.patientService.reschedule('WEEK');
              } else if (error.message === 'WRONG_START_TIME' || error.message === 'CANNOT_START_BEFORE_TIME') {
                this.toastService.showDanger('StartSessionSecheduledDate');
              } else {
                this.toastService.showDanger(this.translateService.instant('DEFAULT'));
              }
              this.activeModal.dismiss({ result: false });
              this.loading = false;
            },
            complete: () => {
              this.loading = false;
              this.subscription.remove(subscription);
              this.logger.debug('ExerciseConfirmModalComponent', 'Session started');
            },
          });
          this.subscription.add(subscription);
      } else {
        this.activeModal.dismiss({ result: false });
        this.toastService.showDanger('Non è stato possibile avviare l\'ambiente 3D per l\'esecuzione della sessione.');
      }
    } catch (e) {
      this.activeModal.dismiss({ result: false });
      this.logger.warn('ExerciseConfirmModalComponent', 'Unable to start the session.', e);
    }
  }

  getTransformSessionData(exercises) {
    return exercises.result.map((row) => ({
      exerciseId: row.exercise.id,
      exerciseName: row.exercise.name,
      exerciseCode: row.exercise.code,
      useWeight: row.exercise.useWeight,
      briefingTitle: row.exercise.briefingTitle,
      briefingDescription: row.exercise.briefingDescription,
      bundleWeb: row.exercise.bundleWeb,
      thumbnail: row.exercise.thumbnail,
      orientation: row.exercise.orientation,
      video: row.exercise.video,
      duration: row.duration,
      breakSeconds: row.breakSeconds,
      exerciseRepetition: row.exerciseRepetitions,
      movementRepetition: row.movementRepetitions
    }));
  }

  protected readonly PatientExerciseStatusEn = PatientExerciseStatusEn;
}
