<div class="custom-modal">
  <div class="modal-header d-flex justify-content-between align-items-start">
    <div>
      <h1 class="mb-0 pb-0">{{translate(session.title)}}</h1>
      <h5>{{translate(session.description)}}</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <p *ngIf="session.pendingLap === undefined">{{'AreYouSureWantStartExercise' | translate}}</p>
    <p *ngIf="session.pendingLap !== undefined">{{'AreYouSureWantContinueSession' | translate}}</p>
  </div>
  <div class="modal-footer">
    <!--<button class="btn bg-black text-white" (click)="no()">{{'ScheduleFor' | translate}}</button>-->
    <button class="btn btn-primary" (click)="yes($event)" [disabled]="loading">
      <ng-container *ngIf="loading">
        <span class="spinner-border spinner-border-sm pe-2" aria-hidden="true"></span>
      </ng-container>
      <span>{{'YesStart' | translate}}</span>
    </button>
  </div>
</div>
