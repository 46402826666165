import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {LoggerService} from "../../core/service/logger.service";

declare global {
  interface Window {
    vuplex: any;
  }
}

export type VuplexEventData = {
  type: string;
  message: any;
};

export type VuplexEvent = {
  data: string | VuplexEventData;
};

export enum VuplexTypes  {
  REFETCH = 'refetch',
  ANAUTHORIZED = 'unauthorized'
}

@Injectable()
export class VuplexService {
    private ready: BehaviorSubject<boolean>;
    unauthorized: Subject<boolean>;

    constructor(
      private logger: LoggerService,
    ) {
        this.ready = new BehaviorSubject<boolean>(false);
        this.unauthorized = new Subject<boolean>();
    }

    initialize() {
      if (window.vuplex) {
        // The window.vuplex object already exists, so go ahead and send the message.
        this.logger.debug('The window.vuplex object already exists.');
        this.ready.next(true);
        window.vuplex.addEventListener('message', this.vuplexMessageListener);
      } else {
        // The window.vuplex object hasn't been initialized yet because the page is still
        // loading, so add an event listener to send the message once it's initialized.
        this.logger.debug('The window.vuplex object hasn\'t been initialized.');
        window.addEventListener('vuplexready', this.vuplexReadyListener);
      }
    }

    postMessage(data: VuplexEventData) {
      if (this.isReady()) {
        window.vuplex.postMessage(data);
      }
    }

    destroy() {
      if (this.isReady()) {
        window.vuplex.removeEventListener('message', this.vuplexMessageListener);
      }
      window.removeEventListener('vuplexready', this.vuplexReadyListener);
    }

    isReady() {
      return this.ready.value;
    }

    asObservable(): Observable<boolean> {
      return this.ready.asObservable();
    }

    private vuplexReadyListener = () => {
      this.ready.next(true);
      window.vuplex.addEventListener('message', this.vuplexMessageListener);
    }

    private vuplexMessageListener  = (event: VuplexEvent) => {
      try {
        const vEventData: VuplexEventData = JSON.parse(event.data as string) as VuplexEventData;
        this.logger.debug('VuplexService', `New message received: ${event.data}`);
        if (vEventData.type.includes(VuplexTypes.REFETCH)) {
          window.location?.reload();
        }
        if (vEventData.type.includes(VuplexTypes.ANAUTHORIZED)) {
          this.unauthorized.next(true);
        }
      } catch (e) {
        this.logger.warn('VuplexService', e);
      }
    }
}
