import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateFilter' })
export class DateFilterPipe implements PipeTransform {
    transform(dateStr: string, element: string): number | string {
        let date = new Date(dateStr);
        if (element === 'date') return date.getDate();
        else if (element === 'month') {
            const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
                "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
            ];
            return monthNames[date.getMonth()];
        }
        else if (element === 'year') return date.getFullYear();
        return '';
    }
}
