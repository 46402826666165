import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILoggedInUser } from 'src/app/shared/dto/logged-in-user.interface';
import { AbstractService } from '../../shared/abstract/service.abstract';
import { IJwtUser } from '../../shared/dto/jwt-user.interface';
import { EnvironmentService } from './environment.service';
import { ThemeService } from 'src/app/core/service/theme.service';

@Injectable()
export class AuthenticationService extends AbstractService<IJwtUser> implements OnDestroy {
  authenticationSubject = new BehaviorSubject<boolean>(false);
  authoritiesSubject = new BehaviorSubject<Array<string>>([]);
  user = new BehaviorSubject<ILoggedInUser | null>(null);
  static USER = 'User';

  private static AUTHORIZATION = 'Authorization';
  token: string | null;

  private subscription = new Subscription();

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    private theme: ThemeService
  ) {
    super();

    this.registerUserChange();
    this.token = this.getAuthToken();
    const user = JSON.parse(localStorage.getItem(AuthenticationService.USER) || '{}');
    this.authenticationSubject.next(!!this.token);
    this.user.next(user);
    if (user?.role) {
      this.authoritiesSubject.next(this.getAuthorities(user?.role));
    }
  }


  getAuthToken(): string | null {
    return localStorage.getItem(AuthenticationService.AUTHORIZATION);
  }

  /**
   * Checks if current user has authority
   * @param authority
   */
  hasAuthority(authority: string): boolean {
    return this.authoritiesSubject?.value.includes(authority) === true;
  }

  /**
   * Checks if current user has authorities
   * @param authorities
   */
  hasAuthorities(authorities: Array<string> = []): boolean {
    return authorities.some(authority => this.authoritiesSubject?.value.find(a => a === authority) !== undefined);
  }

  /**
   * Returns array of authorities from user role
   */
  getAuthorities(role?: string): Array<string> {
    return role?.length ? _.split(role, ',') : [];
  }

  /**
   * The observable api of authority
   */
  authorities(): Observable<Array<string>> | null {
    return this.authoritiesSubject ? this.authoritiesSubject.asObservable() : null;
  }

  /**
   * The observable api of authentication status
   */
  valueChanges(): Observable<boolean> {
    return this.authenticationSubject.asObservable();
  }

  /**
   * Verify the authentication
   */
  verify(): void {
    this.token = localStorage.getItem(AuthenticationService.AUTHORIZATION);
    // const aud: string = this.payload('aud');
    // if (aud === null || aud !== AuthenticationService.TOKEN_AUD) {
    //     localStorage.clear();
    //     this.token = null;
    //     this.user = null;
    //     this.authenticationSubject.next(false);
    //     this.authoritiesSubject?.next([]);
    //     this.router.navigate(['login'], { relativeTo: this.route }).catch(this.handleError);
    // }
  }

  /**
   * Do login http post to retrieve Authorization token and User details
   * @param email
   * @param password
   * @param rememberMe
   */
  login(email: string, password: string, rememberMe: boolean): Observable<ILoggedInUser> {
    const url = this.environmentService.getApiUrl('auth/login', {}, null);
    return this.httpClient.post(url, { email, password }, { observe: 'response' })
      .pipe(map((httpResponse) => {
        if (httpResponse.type === 4) {
          const body = httpResponse?.body as { accessToken: string, userId: number };
          this.token = `${body?.accessToken}`;
          const jwtDecoded = jwt_decode(this.token) as IJwtUser;
          localStorage.setItem(AuthenticationService.AUTHORIZATION, this.token);
          const updatedUser = { ...jwtDecoded, id: body.userId };
          this.updateLoggedInUserState(updatedUser);
          this.authenticationSubject.next(true);
          this.authoritiesSubject?.next(this.getAuthorities(jwtDecoded?.role));
        }
        return this.user.value as ILoggedInUser;
      }));
  }

  updateLoggedInUserState(updatedUser: ILoggedInUser): void {
    this.user.next(updatedUser);
    localStorage.setItem(AuthenticationService.USER, JSON.stringify(updatedUser));
  }

  /**
   * Do log out from the system
   */
  logout(): Observable<void> {
    const url = this.environmentService.getApiUrl('auth/logout', {}, null);
    return this.httpClient.post(url, {})
      .pipe(map(() => {
        this.clearSession();
      }));
  }

  clearSession(): void {
    localStorage.clear();
    this.token = null;
    this.user.next(null);
    this.authenticationSubject.next(false);
    this.authoritiesSubject?.next([]);
  }

  /**
   * Retrieves value from token payload
   */
  payload(attribute: string): any {
    try {
      if (this.token) {
        const payload: any = jwt_decode(this.token);
        return payload[attribute];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  registerUserChange(): void {
    const sub = this.user.subscribe({
      next: (user) => {
        if (user?.role) {
          this.theme.role = user.role;
          this.theme.current = user.role.toLowerCase();
        }
      }
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
