import { Base } from '../base.model';

export class Breadcrumb extends Base {
    title: string;
    url: string;

    constructor(title: string, url?: string) {
        super();
        this.title = title;
        if (url) this.url = url;
    }
}
