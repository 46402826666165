import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { EnvironmentService } from 'src/app/core/service/environment.service';
import { DeletePatient, IAppointmentDetails, IAppointments, IPatient } from 'src/app/shared/dto/data/clients.interface';
import { IPassword } from 'src/app/shared/dto/data/password.interface';
import { IDoctor } from 'src/app/shared/dto/data/doctor.interface';
import { IEvaluationSurvey, IEvaluationSurveyType } from 'src/app/shared/dto/data/evaluation-survey.interface';
import { IInstrumentalData } from 'src/app/shared/dto/data/instrumental-data';
import { IAppointmentSaveData, IAppointmentUpdateData, IPatientList } from 'src/app/shared/dto/data/getPatientList.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class DoctorService {
  doctorProfile$ = new BehaviorSubject<IDoctor>(null as unknown as IDoctor);

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private translate: TranslateService) { }

  getDoctorProfile(doctorId: number, kpi: boolean = false): Observable<IDoctor> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId', { doctorId, kpi });
    return this.http.get<IDoctor>(url);
  }

  addNewPatient(doctorId: number, formData: IPatient): Observable<IPatient> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients', { doctorId });
    return this.http.post<IPatient>(url, formData);
  }

  getPatientProfileByDoctor(doctorId: number, patientId: number, todayDateStr: string): Observable<IPatient> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId', { doctorId, patientId, todayDateStr });
    return this.http.get<IPatient>(url);
  }

  savePatientProfileByDoctor(doctorId: number, patientId: number, patientData: IPatient): Observable<IPatient> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId', { doctorId, patientId });
    return this.http.put<IPatient>(url, patientData);
  }

  saveDoctorPassword(doctorId: number, formData: IPassword): Observable<void> {
    const { oldPassword, newPassword, confirmPassword } = formData;
    const url = this.environmentService.getApiUrl('doctors/:doctorId/password', { doctorId });
    return this.http.put<void>(url, { oldPassword, newPassword, confirmPassword });
  }

  getPathologyCriteriasByPathology(pathology: string): Observable<any> {
    const url = this.environmentService.getApiUrl('pathology-criterias/:pathology', { pathology });
    return this.http.get<any>(url);
  }

  getPathologyCriteriasByPatient(doctorId: number, patientId: number): Observable<any> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/pathology-criterias', { doctorId, patientId });
    return this.http.get<any>(url);
  }

  savePathologyCriterias(doctorId: number, patientId: number, pathologyCriteriasData: any): Observable<any> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/pathology-criterias', { doctorId, patientId });
    return this.http.post<IPatient>(url, pathologyCriteriasData);
  }

  getInstrumentalData(doctorId: number, patientId: number) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/instrumental-data', { doctorId, patientId });
    return this.http.get<any>(url);
  }

  saveInstrumentalData(doctorId: number, patientId: number, instrumentalData: any): Observable<any> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/instrumental-data', { doctorId, patientId });
    return this.http.post<IInstrumentalData>(url, instrumentalData);
  }

  getDoctorAppointments(doctorId: number, weekStartDate: string): Observable<IAppointments[]> {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/appointments/weekly?weekStartDate=:weekStartDate', { doctorId, weekStartDate });
    return this.http.get<IAppointments[]>(url).pipe(map(this.transformAppointments));

  }

  transformAppointments(appointment) {
    const result: any[] = [];

    appointment.forEach(item => {

      item.appointments.forEach(appointment => {
        const { firstName, id } = appointment.patient;
        const date = appointment.date;
        const status = appointment.status == null ? 'PENDING' : appointment.status;
        result.push({
          appointmentId: appointment.id,
          appointmentDate: date,
          status: status,
          title: firstName,
          start: new Date(date).toISOString(),
          patient: appointment.patient,
          backgroundColor: appointment.status == null ? 'yellow' : appointment.status == 'ACCEPTED' ? 'green' : appointment.status == 'DENIED' ? 'red' : 'yellow'
        });
      });
    });

    return result;
  }

  getPatientList(doctorId: number) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/list/dropdown', { doctorId });
    return this.http.get<IPatientList>(url);
  }

  saveAppointmentData(doctorId: number, appointmentData: IAppointmentSaveData) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/appointments', { doctorId });
    return this.http.post<IAppointments>(url, appointmentData);
  }

  deleteAppointment(doctorId: number, appointmentId: number) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/appointments/:appointmentId', { doctorId, appointmentId });
    return this.http.delete<IAppointmentDetails>(url);
  }

  updateAppointment(doctorId: number, patientId: number, appointmentId: number, formData: IAppointmentUpdateData) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/appointments/:appointmentId', { doctorId, patientId, appointmentId });
    return this.http.put<IAppointmentDetails>(url, formData);
  }

  getPatientCallData(doctorId: number, patientId: number) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId/call', { doctorId, patientId });
    return this.http.get<IPatient>(url);
  }

  deletePatient(doctorId: number, patientId: number, formData) {
    const url = this.environmentService.getApiUrl('doctors/:doctorId/patients/:patientId', { doctorId, patientId });
    return this.http.put<IPatient>(url, formData);
  }
}
