import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { PatientService } from 'src/app/services/http/patient.service';
import { ILoggedInUser } from '../../dto/logged-in-user.interface';
import { ToastService } from 'src/app/services/component/toast.service';
import { Router } from '@angular/router';
import { ConfirmPasswordValidator } from "../../validator/confirm-password.validator";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  formPassword: UntypedFormGroup;
  formSubmitted: boolean = false;
  loggedInUser: ILoggedInUser | null;
  patientId: number;

  constructor(
    public activeModal: NgbActiveModal,
    public patientService: PatientService,
    public authService: AuthenticationService,
    public toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.formPassword = this.fb.group({
      oldPassword: new UntypedFormControl('', [Validators.required,]),
      newPassword: new UntypedFormControl('', [Validators.required,]),
      confirmPassword: new UntypedFormControl('', [Validators.required,])
    },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirmPassword")
      });
  }

  ngOnInit(): void {
    this.authService.user.subscribe({
      next: (user: ILoggedInUser | null) => {
        this.loggedInUser = user;
        this.patientId = this.loggedInUser?.id as number;
      },
      error: (err: Error) => this.toastService.showDanger(err?.message)
    });
  }

  savePassword() {
    this.formSubmitted = true;
    if (this.formPassword.valid) {
      const formData = this.formPassword.value;
      this.patientService.savePatientPassword(this.patientId, formData).subscribe({
        next: () => {
          this.modalService.dismissAll();
          this.router.navigate(['']).catch((err: Error) => this.toastService.showDanger(err?.message))
        },
        error: (err: Error) => this.toastService.showDanger('EntervalidOldPassword')
      })
    } else {
      this.toastService.showDanger('FormError');
    }
  }

}
