<div class="custom-modal">
  <div class="modal-header d-flex justify-content-between align-items-start">
    <div>
      <h1 class="mb-0 pb-0">{{'RecoverSession' | translate}}</h1>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()" [disabled]="loading"></button>
  </div>
  <div class="modal-body">
    <ngb-alert [type]="'warning'" [dismissible]="false">{{'RecoverSessionWarning' | translate}}</ngb-alert>
    <p class="text-muted">{{'AreYouSureWantRecoverSession' | translate}}</p>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <ngb-datepicker #dp (navigate)="date = $event.next" [markDisabled]="markDisabled" (dateSelect)="onDateSelect($event)"
                      [minDate]="minDate" [maxDate]="maxDate"></ngb-datepicker>
      <div *ngIf="selectedDate">
        <small>{{'StartExerciseSelectedDate' | translate}} {{selectedDate | date: 'dd/MM/yyyy' }}</small>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button class="btn bg-black text-white" (click)="no()" [disabled]="loading">
        {{'No' | translate}}
      </button>
      <button class="btn btn-primary" (click)="yes($event)" [disabled]="loading">
        <ng-container *ngIf="loading">
          <span class="spinner-border spinner-border-sm pe-2" aria-hidden="true"></span>
        </ng-container>
        <span>{{'YesReset' | translate}}</span>
      </button>
  </div>
</div>
